const payment = (state = {
    paymentProcessing: false,
    bills: [],
    payments: []
}, action) => {
    switch (action.type) {
        case 'PROCESSING_PAYMENT':
            return Object.assign({}, state, {
                paymentProcessing: action.processing
            })
        case 'GET_PAYMENTS':
            return Object.assign({}, state, {
                payments: action.payments
            })
        case 'GET_BILLS':
            return Object.assign({}, state, {
                bills: action.bills
            })
        default:
            return state
    }
}

export default payment