import React from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardBody,
    CardTitle, CardDeck, CardHeader
} from 'reactstrap';
import moment from 'moment-timezone';
import DropdownList from 'react-widgets/lib/DropdownList'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './PatientMessaging.css'

let textMessagePatient
let onChangeText = (val) => {
    textMessagePatient = val.value
}
let hasRequested = false
class PatientMessagingComp extends React.Component {
    componentDidUpdate() {
        let { getMessages, user, isProvider } = this.props
        if (user != undefined, !hasRequested) {
            console.log("FETCHING MESSAGES")
            hasRequested = true
            getMessages(user, isProvider)
        }

    }
    componentWillUnmount() {
        hasRequested = false
    }
    render() {
        const props = this.props
        let Selector = undefined
        if (props.isProvider) {
            const patientList = []

            for (let patient of props.patients.list) {
                patientList.push({
                    name: `${patient.firstname} ${patient.lastname}`,
                    value: patient
                })
            }
            Selector = (<Col className="textMessage">
                <label>Patient</label>
                <DropdownList
                    id="patient-text"
                    data={patientList}
                    valueField="value"
                    onChange={onChangeText}
                    textField="name" />
            </Col>)
        } else {
            const providerList = []

            for (let provider of props.providers.list) {
                providerList.push({
                    name: `${provider.name}`,
                    value: provider
                })
            }
            Selector = (<Col className="textMessage">
                <label>Provider</label>
                <DropdownList
                    id="patient-text"
                    data={providerList}
                    valueField="value"
                    onChange={onChangeText}
                    textField="name" />
            </Col>)
        }

        return (
            <div>
                <Header logout={props.logout} isProvider={props.isProvider} />
                <Container fluid={true}>
                    <Row>
                        <Col><h1>Create and View Messages</h1></Col>
                    </Row>
                    <Row>
                        {Selector}
                    </Row>
                    <Row>
                        <Col>
                            <button className="btn" onClick={() => { props.toggleMessageModal(textMessagePatient) }}>Create New Message</button>
                        </Col>
                    </Row>
                    <CardDeck>
                        {props.messages.list.map(message => {
                            let start = moment.tz(message.dateTime, message.timezone).clone().tz(moment.tz.guess())
                            return (
                                <Card key={message.id} onClick={() => {
                                    props.selectedConversation(message.id, message.name)
                                }}>
                                    <CardHeader className="heading"><h3>{message.name}</h3></CardHeader>
                                    <CardBody>
                                        <CardTitle className="sub-heading">Sent: {start.format('MMM Do YYYY h:mm a')}</CardTitle>
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </CardDeck>

                </Container>
                <Footer />
                <Modal isOpen={props.messageModal.active} toggle={() => { props.toggleMessageModal(null) }}>
                    <ModalHeader toggle={() => { props.toggleMessageModal(null) }}>Send Message</ModalHeader>
                    <ModalBody>
                        <p>Message:</p>
                        <textarea id="messageModalText" name="messageModalText" maxLength="160" type="text" placeholder="Enter Your Message">
                        </textarea>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="confirm" onClick={() => {
                            let message = document.getElementById("messageModalText").value
                            props.sendMessage(message, props.user, props.messageModal.person, props.isProvider)
                        }}>Send Message</Button>{' '}
                        <Button className="cancel" onClick={() => { props.toggleMessageModal(null) }}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}

export default PatientMessagingComp