import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardDeck, CardHeader
} from 'reactstrap';
import moment from 'moment-timezone';
import './BillList.css'

const BillList = (props) => {
    if (props.bills !== null && props.bills !== undefined) {
        return (
            <CardDeck>
                {props.bills.sort((a, b) => {
                    if (a.due.dateTime < b.due.dateTime) {
                        return -1;
                    }
                    if (a.due.dateTime > b.due.dateTime) {
                        return 1;
                    }
                    return 0;
                }).map(bill => {
                    let date = moment.tz(bill.date.dateTime, bill.date.dateTime.timezone).clone().tz(moment.tz.guess())
                    let due = moment.tz(bill.due.dateTime, bill.due.dateTime.timezone).clone().tz(moment.tz.guess())
                    return (
                        <Card key={bill.id}>
                            <CardHeader><h3>{bill.title}</h3></CardHeader>
                            <CardBody>
                                <CardTitle>Amount: ${bill.amount}</CardTitle>
                                <CardText>{bill.description}</CardText>
                                <CardText>Billed By: {bill.provider_name}</CardText>
                                <CardText>Billed To: {bill.patient_name}</CardText>
                                <CardText>Date of Service: {date.format('MMM Do YYYY')}</CardText>
                                <CardText>Due By: {due.format('MMM Do YYYY')}</CardText>
                                <CardText>Status: {bill.paid ? "Paid" : "Unpaid"}</CardText>
                            </CardBody>
                        </Card>
                    )
                })}
            </CardDeck>
        )
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default BillList