const user = (state = {
    current: undefined,
    profile: undefined
}, action) => {
    switch (action.type) {
        case 'ADD_USER':
            return Object.assign({}, state, {
                current: action.user
            })
        case 'REMOVE_USER':
            return Object.assign({}, state, {
                current: undefined,
                profile: undefined
            })
        case 'SET_PROFILE':
            return Object.assign({}, state, {
                profile: action.profile
            })
        case 'CLEAR_PROFILE':
            return Object.assign({}, state, {
                profile: undefined
            })
        default:
            return state
    }
}

export default user