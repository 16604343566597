import { connect } from 'react-redux'
import {
    processingPayment,
    fetchPayments,
    fetchBills
} from '../actions'
import PaymentForm from '../components/PaymentForm'
const mapStateToProps = state => {
    let patients
    patients = state.patients.list
    return {
        user: state.user.current,
        processing: state.payment.paymentProcessing,
        bills: state.payment.bills,
        payments: state.payment.payments,
        patients
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        paymentProcessing: processing => {
            dispatch(processingPayment(processing))
        },
        getPayments: (user) => {
            dispatch(fetchPayments(user))
        },
        getBills: (user) => {
            dispatch(fetchBills(user, true))
        }
    }
}

const PaymentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentForm)

export default PaymentContainer