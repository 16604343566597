import React from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  NavbarToggler
} from 'reactstrap';
import './Header.css'
import strings from '../../strings'

const Header = (props) => {
  let profileLink
  let messageLink
  let appointmentsLink = (<NavItem>
    <NavLink href="/">Appointments</NavLink>
  </NavItem>)
  let paymentLink = (<NavItem>
    <NavLink href="/payments">Payments</NavLink>
  </NavItem>)
  if (!props.isProvider) {
    profileLink = (<NavItem>
      <NavLink href="/profile">Update Profile</NavLink>
    </NavItem>)
  } else if (props.isProvider) {
    profileLink = (<NavItem>
      <NavLink href="/preferences">Update Preferences</NavLink>
    </NavItem>)
  }

  messageLink = (<NavItem>
    <NavLink href="/messaging">Messages</NavLink>
  </NavItem>)


  if (props.isHome) {
    return (
      <Navbar color="dark" dark expand="lg">
        <NavbarBrand href="/">
          <img src={process.env.REACT_APP_LOGO} alt="Anywhere Healthcare" height="120" width="250" />
        </NavbarBrand>
      </Navbar>
    )
  } else {
    return (
      <Navbar color="dark" dark expand="lg">
        <NavbarBrand href="/">
          <img src={process.env.REACT_APP_LOGO} alt="Anywhere Healthcare" height="120" width="250" />
        </NavbarBrand>
        <NavbarToggler onClick={props.toggleNav} />
        <Collapse isOpen={props.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {appointmentsLink}
            {profileLink}
            {messageLink}
            {paymentLink}
            <NavItem>
              <NavLink href="/" onClick={props.logout}>Logout</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }

}

export default Header