import { connect } from 'react-redux'
import Login from '../components/Login'
import swal from 'sweetalert';

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (event) => {
            event.preventDefault();
            let email = event.target[0].value
            let password = event.target[1].value
            ownProps.auth.signInWithEmailAndPassword(email, password).then(() => {
                ownProps.history.push('/')
            }).catch(function (error) {
                // Handle Errors here.
                var errorMessage = error.message;
                swal({
                    title: "Sign In Error",
                    text: errorMessage,
                    icon: "error",
                });
            });
        }
    }
}

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default LoginContainer