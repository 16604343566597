import { connect } from 'react-redux'
import {
    createRequest,
    requestSubmitting
} from '../actions'
import moment from 'moment-timezone'
import RequestForm from '../components/RequestForm'

const mapStateToProps = state => {
    return {
        providers: state.providers,
        user: state.user.current,
        isSubmitting: state.requests.isSubmitting
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: (data, user) => {
            let date = moment(data.date)
            date.hour(data.time.value)
            date.minute(0)
            date.second(0)
            date.millisecond(0)
            let request = {
                title: data.title,
                description: data.description,
                date: date.toISOString(),
                timezone: moment.tz.guess(),
                duration: data.duration,
                providerName: data.provider.name,
                providerID: data.provider.value.id,
                patientUID: user.uid,
                patientEmail: user.email,
                patientName: user.displayName
            }
            dispatch(requestSubmitting(true))
            dispatch(createRequest(request, user))
        },
        resetSubmit: () => {
            dispatch(requestSubmitting(false))
        }
    }
}

const RequestContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestForm)

export default RequestContainer