import swal from 'sweetalert';
const modal = (state = {
    messageModal: {
        active: false,
        person: undefined,
        isProvider: undefined
    }
}, action) => {
    switch (action.type) {
        case 'TOGGLE_MESSAGE_MODAL':
            return Object.assign({}, state, {
                messageModal: {
                    active: !state.messageModal.active,
                    person: action.person,
                    appointment: action.appointment,
                    isProvider: action.isProvider
                }
            })
        case 'TOGGLE_EMAIL_MODAL':
            return Object.assign({}, state, {
                emailModal: {
                    active: !state.emailModal.active,
                    patient: action.patient
                }
            })
        case 'MESSAGE_SENT':
            if (action.err) {
                swal({
                    title: "Error Sending Message",
                    text: action.err,
                    icon: "error",
                });
            } else {
                swal({
                    title: "Message Sent",
                    text: "Your message was sent Successfully",
                    icon: "success",
                });
            }
            return Object.assign({}, state, {
                messageModal: {
                    active: !state.messageModal.active,
                    patient: undefined
                }
            })
        default:
            return state
    }
}

export default modal