import { connect } from 'react-redux'
import Header from '../components/Header'
import { toggleNav } from '../actions'
const mapStateToProps = state => {
    return {
        isOpen: state.nav.isOpen
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...ownProps,
        toggleNav: () => {
            dispatch(toggleNav())
        }
    }
}

const NavContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)

export default NavContainer