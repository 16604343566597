const providers = (state = {
    list: [],
    country: null,
    state: null,
    company: null,
    queued: undefined
}, action) => {
    switch (action.type) {
        case 'GET_PROVIDERS':
            if (!action.providers) {
                return Object.assign({}, state, {
                    list: []
                })
            }
            return Object.assign({}, state, {
                list: action.providers
            })
        case 'SELECT_PROVIDER_COUNTRY':
            return Object.assign({}, state, {
                country: action.country
            })
        case 'SELECT_PROVIDER_STATE':
            return Object.assign({}, state, {
                state: action.state
            })
        case 'SELECT_PROVIDER_COMPANY':
            return Object.assign({}, state, {
                company: action.company
            })
        case 'QUEUE_PROVIDER':
            return Object.assign({}, state, {
                queued: action.provider
            })
        default:
            return state
    }
}

export default providers