import React from 'react'
import {
    Card, CardImg, CardText, CardBody, Container,
    CardTitle, CardSubtitle, CardDeck, CardHeader
} from 'reactstrap';
import { Link } from 'react-router-dom'
import moment from 'moment-timezone';
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './Posts.css'

const createMarkup = content => {
    return { __html: content }
}

const PostsList = (props) => {
    if (props.posts !== null && props.posts !== undefined) {
        return (<div>
            <Header logout={props.logout} isProvider={props.isProvider} />
            <Container fluid={true}>
                <h1>Blog Posts</h1>
                <CardDeck>
                    {props.posts.map(post => {
                        return (
                            <Link to={{
                                pathname: '/view-post',
                                state: { post }
                            }}>
                                <Card key={post.id}>
                                    <CardHeader className="heading"><h3>{post.title.rendered}</h3></CardHeader>
                                    <CardBody dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}>

                                    </CardBody>
                                </Card>
                            </Link>
                        )
                    })}
                </CardDeck>
            </Container>
            <Footer />
        </div>)
    } else {
        return (
            <div>
                <Header logout={props.logout} />
                <Container fluid={true}>
                    <h1>Blog Posts</h1>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default PostsList