import React from 'react'
import moment from 'moment-timezone';
import {
    Container,
    Row,
    Col,
    Button,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from 'reactstrap';
import Footer from '../Footer'
import Header from '../../containers/NavContainer'

const showCancellationPopUp = (message, deleteMessage) => {
    deleteMessage(message)
}
let hasRequested = false
class MessageList extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.submitReply = this.submitReply.bind(this)
        this.replyText = undefined
    }

    submitReply() {
        this.props.reply(this.replyText, this.props.user, this.props.selected, this.props.isProvider)
        this.replyText = ""
        let field = document.getElementById("replyText")
        field.value = ""
    }

    handleChange(event = {}) {
        let field = document.getElementById("replyText")
        this.replyText = field.value
    }

    componentDidUpdate() {
        let { getMessages, user, isProvider } = this.props
        if (user != undefined, !hasRequested) {
            console.log("FETCHING MESSAGES")
            hasRequested = true
            getMessages(user, isProvider)
        }

    }
    componentWillUnmount() {
        hasRequested = false
    }

    render() {
        let props = this.props
        let replyText = this.replyText
        if (this.props.messages !== undefined &&
            this.props.messages.length > 0) {
            return (
                <div>
                    <Header logout={this.props.logout} isProvider={this.props.isProvider} />
                    <Container fluid={true}>
                        <Row>
                            <Col><h1>Your Conversation</h1></Col>
                        </Row>



                        <ListGroup>
                            {this.props.messages.sort((a, b) => {
                                let aStart = moment.tz(a.dateTime, a.timezone).clone().tz(moment.tz.guess())
                                let bStart = moment.tz(b.dateTime, b.timezone).clone().tz(moment.tz.guess())
                                return bStart.isSameOrBefore(aStart)
                            }).map(message => {
                                let start = moment.tz(message.dateTime, message.timezone).clone().tz(moment.tz.guess())
                                let whoAmI = this.props.isProvider ? "provider" : "patient"
                                let name
                                let deleteButton
                                if (message.sender === whoAmI) {
                                    name = "You"
                                    deleteButton = (<Button onClick={(e) => { this.props.delete(message.id) }}>Delete</Button>)
                                } else {
                                    name = this.props.isProvider ? message.patientName : message.providerName
                                    deleteButton = undefined
                                }
                                return (
                                    <ListGroupItem key={message.id}>
                                        <ListGroupItemHeading className="heading"><h3>{name}</h3></ListGroupItemHeading>
                                        <ListGroupItemText className="sub-heading">Sent: {start.format('MMM Do YYYY h:mm a')}</ListGroupItemText>
                                        <ListGroupItemText>{message.message}</ListGroupItemText>
                                        {deleteButton}
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                        <div>
                            <input id="replyText" type="text" onChange={this.handleChange} />
                            <Button onClick={this.submitReply}>Send Reply</Button>
                        </div>
                    </Container>
                    <Footer />
                </div>
            )
        } else {
            return (
                <div>
                    <Header logout={this.props.logout} isProvider={this.props.isProvider} />
                    <Container fluid={true}>
                        <Row>
                            <Col><h1>Your Conversation</h1></Col>
                        </Row>
                    </Container>
                    <Footer />
                </div>
            )
        }
    }
}

export default MessageList