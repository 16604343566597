/* eslint-disable */
import React from 'react'
import swal from 'sweetalert';
import SquarePaymentForm from 'react-square-hosted-fields';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import DropdownList from 'react-widgets/lib/DropdownList'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import BillList from '../BillList'
import PaymentList from '../PaymentList'
import config from '../../config'
import moment from 'moment-timezone'
import './PaymentForm.css'

// Set the application ID
var applicationId = config.square.applicationId;

// Set the location ID
var location_id = config.square.location_id;

let bill_id
let amount
let onChangeText = (val) => {
    bill_id = val.value.id,
        amount = Math.trunc(parseFloat(val.value.amount) * 100)
}

let patient_uid
let patient_name
let onChangePatient = (val) => {
    patient_uid = val.value.uid
    patient_name = `${val.value.firstname} ${val.value.lastname}`
}

let serviceDate
let onServiceChange = (val) => {
    serviceDate = moment(val).toISOString();
}

let dueDate
let onDueChange = (val) => {
    dueDate = moment(val).toISOString();
}

const sendBill = (user) => {
    let errors = []
    let amount = document.getElementById("amount").value
    let title = document.getElementById("title").value
    let description = document.getElementById("description").value
    if (!amount) {
        errors.push("Amount is required")
    } else if (amount < 0.01) {
        errors.push("Amount must be greater than 0")
    }
    if (!title && title.length > 0) {
        errors.push("Bill title is required")
    }
    if (!dueDate) {
        errors.push("Due Date is required")
    }
    if (!serviceDate) {
        errors.push("Service is required")
    }
    if (!patient_name || !patient_uid) {
        errors.push("Must select a patient")
    }
    if (errors.length > 0) {
        let errorMessage = ""
        for (let error of errors) {
            errorMessage += `${error} \n`
        }
        swal({
            title: "Missing Info",
            text: errorMessage,
            icon: "error",
        });
    } else {
        const payload = {
            patient_uid,
            patient_name,
            provider_uid: user.uid,
            provider_name: `${user.displayName}`,
            amount,
            title,
            description,
            date: {
                dateTime: serviceDate,
                timeZone: moment.tz.guess()
            },
            due: {
                dateTime: dueDate,
                timeZone: moment.tz.guess()
            },
            paid: false
        }
        processingFunction(true)
        fetch(`${config.functions.host}/postBill`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Method': 'POST'
                },
                mode: 'cors',
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then((res) => {
                if (res.ok) {
                    processingFunction(false)
                    fetchBills(user)
                    swal({
                        title: "Bill Sent",
                        text: "Bill Sent Successfully",
                        icon: "success",
                    });
                } else {
                    processingFunction(false)
                    swal({
                        title: "Error Posting Bill",
                        text: "There was an error sending your bill",
                        icon: "error",
                    });
                }
            }).catch((err) => {
                processingFunction(false)
                swal({
                    title: "Error Posting Bill",
                    text: err,
                    icon: "error",
                });
            })
    }
}

const handleNonce = (nonce) => {

    let buttonWrapper = document.getElementsByClassName('sq-button-wrapper')[0]
    // Square expects payment amount to be in the base unit for a currency
    // For USD this is cents, hence we multiply by 100
    // Square also requires an integer so using Math.trunc ensures this
    let note = document.getElementById('note').value || ""
    processingFunction(true)
    if (amount == null || amount == undefined || isNaN(amount) || amount <= 0) {
        processingFunction(false)
        swal({
            title: "Missing Info",
            text: "Must provide an amount greater than 0",
            icon: "error",
        });
        return
    }
    if (bill_id == null || bill_id == undefined) {
        processingFunction(false)
        swal({
            title: "Missing Info",
            text: "Must associate a payment to a bill",
            icon: "error",
        });
        return
    }
    const payload = {
        amount,
        bill_id,
        location_id,
        nonce,
        uid: user.uid,
        note,
        date: {
            dateTime: moment().format(),
            timezone: moment.tz.guess()
        }
    }
    fetch(`${config.functions.host}/processPayment`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            mode: 'cors',
            method: "POST",
            body: JSON.stringify(payload)
        })
        .then(function (res) {
            processingFunction(false)
            if (res.status >= 400) {
                let err = res.json().then((val) => {
                    let err = val
                    let errorMessage = ""
                    for (let error of err.errors) {
                        errorMessage += `${error.detail}\n`
                    }
                    swal({
                        title: "Something went wrong",
                        text: errorMessage,
                        icon: "error",
                    });
                    button.disabled = false
                    return
                }).catch(function (res) {
                    swal({
                        title: "Something went wrong",
                        text: "Error Processing Payment",
                        icon: "error",
                    });
                    button.disabled = false
                    return
                })
            }
            fetchPayments(user)
            swal({
                title: "Success",
                text: "Payment Processed sucessfully",
                icon: "success",
            });
        })
        .catch(function (res) {
            processingFunction(false)
            swal({
                title: "Something went wrong",
                text: "Error Processing Payment",
                icon: "error",
            });
        })
}

const handleError = (errs) => {
    processingFunction(false)
    let buttonWrapper = document.getElementsByClassName('sq-button-wrapper')[0]
    let button = buttonWrapper.getElementsByTagName('button')[0]
    button.disabled = false
    let message = ""
    for (let err of errs) {
        message += `${err.message} \n`
    }
    swal({
        title: "Issue Processing Card",
        text: message,
        icon: "error",
    });
}

const amountReducer = (total, object) => {
    return parseFloat(total) + parseFloat(object.amount)
}

const calculateBalance = (payments, bills) => {
    const owe = bills.reduce(amountReducer, 0)
    const paid = payments.reduce(amountReducer, 0)
    return owe - paid
}

let processingFunction
let user
let fetchPayments
let fetchBills
class PaymentForm extends React.Component {


        buildPatientPayments() {
                const balance = calculateBalance(this.props.payments, this.props.bills)
                const formattedBalance = Number(Math.round(balance + 'e2') + 'e-2').toFixed(2);
                let billList = this.props.bills.filter((bill) => {
                    return !bill.paid
                }).map((bill) => {
                    var formattedBill = {
                        name: `$${bill.amount} - ${bill.title}`,
                        value: {
                            id: bill.id,
                            amount: bill.amount
                        }
                    };
                    return formattedBill;
                })
                let Form
                let payments
                let balanceComp

                if (this.props.processing) {
                    Form = < p > Your Payment is being Processed < /p>
                } else {
                    Form = ( < div >
                        <
                        div className = "bill" >
                        <
                        label htmlFor = "bill" > Bill < /label> <
                        DropdownList id = "bill"
                        data = {
                            billList
                        }
                        valueField = "value"
                        onChange = {
                            onChangeText
                        }
                        textField = "name" / >
                        <
                        /div> <
                        div className = "note form-input" >
                        <
                        label htmlFor = "note" > Note < /label> <
                        input type = "text"
                        id = "note"
                        name = "note" / >
                        <
                        /div> <
                        SquarePaymentForm appId = {
                            applicationId
                        }
                        onNonceGenerated = {
                            handleNonce
                        }
                        onNonceError = {
                            handleError
                        }
                        /> <
                        a className = "btn"
                        href = "/" > Cancel < /a> < /
                        div > )
                }
                balanceComp = ( < Col className = "paymentBalance" >
                    <
                    h2 > Balance < /h2> <
                    p className = "balance" > You Owe: $ {
                        formattedBalance
                    } < /p> < /
                    Col > )
                payments = ( < Col className = "paymentPayments" >
                    <
                    h2 > Payments < /h2> <
                    PaymentList payments = {
                        this.props.payments
                    }
                    bills = {
                        this.props.bills
                    }
                    /> < /
                    Col > )

                return ( <
                    div >
                    <
                    Header logout = {
                        this.props.logout
                    }
                    isProvider = {
                        false
                    }
                    />

                    <
                    Container fluid = {
                        true
                    }
                    className = "paymentContainer" >
                    <
                    h1 > Payment Information < /h1> <
                    Row > {
                        balanceComp
                    } <
                    /Row> <
                    Row >
                    <
                    Col className = "paymentForm" >
                    <
                    h2 > Make a Payment < /h2> <
                    div className = "form" > {
                        Form
                    } <
                    /div> < /
                    Col > <
                    /Row> <
                    Row >
                    <
                    Col className = "paymentBills" >
                    <
                    h2 > Bills < /h2> <
                    BillList bills = {
                        this.props.bills
                    }
                    /> < /
                    Col > <
                    /Row> <
                    Row className = "paymentRow" > {
                        payments
                    } <
                    /Row> < /
                    Container > <
                    Footer / >
                    <
                    /div>)
                }

                buildProviderBilling() {
                        let Form
                        if (this.props.processing) {
                            Form = < p > Your Bill is being Processed < /p>
                        } else {
                            let patientList = []
                            for (let patient of this.props.patients) {
                                patientList.push({
                                    name: `${patient.firstname} ${patient.lastname}`,
                                    value: patient
                                })
                            }
                            Form = ( < div >
                                <
                                div >
                                <
                                label > Patient < /label> <
                                DropdownList id = "patient-text"
                                data = {
                                    patientList
                                }
                                valueField = "value"
                                onChange = {
                                    onChangePatient
                                }
                                textField = "name" / >

                                <
                                /div> <
                                div className = "form-input" >
                                <
                                label htmlFor = "title" > Title < /label> <
                                input id = "title"
                                name = "title"
                                type = "text" / >
                                <
                                /div> <
                                div className = "form-input" >
                                <
                                label htmlFor = "description" > Description < /label> <
                                textarea id = "description"
                                name = "description"
                                maxLength = "300" > < /textarea> < /
                                div > <
                                div className = "form-input" >
                                <
                                label htmlFor = "amount" > Amount < /label> <
                                input id = "amount"
                                name = "amount"
                                type = "number" / >
                                <
                                /div> <
                                div >
                                <
                                label htmlFor = "serviceDate" > Date of Service < /label> <
                                DateTimePicker id = "serviceDate"
                                name = "serviceDate"
                                onChange = {
                                    onServiceChange
                                }
                                format = "DD MMM YYYY"
                                time = {
                                    false
                                }
                                /> < /
                                div > <
                                div >
                                <
                                label htmlFor = "dueDate" > Due Date < /label> <
                                DateTimePicker id = "dueDate"
                                name = "dueDate"
                                onChange = {
                                    onDueChange
                                }
                                format = "DD MMM YYYY"
                                time = {
                                    false
                                }
                                /> < /
                                div > <
                                Button className = "btn confirm"
                                onClick = {
                                    () => {
                                        sendBill(this.props.user)
                                    }
                                } > Send Bill < /Button> <
                                a className = "btn cancel"
                                href = "/" > Cancel < /a> < /
                                div > )
                        }
                        return ( <
                            div >
                            <
                            Header logout = {
                                this.props.logout
                            }
                            isProvider = {
                                true
                            }
                            />

                            <
                            Container fluid = {
                                true
                            }
                            className = "paymentContainer" >
                            <
                            h1 > Bill Information < /h1> <
                            Row >
                            <
                            Col className = "paymentForm" >
                            <
                            h2 > Send a Bill < /h2> <
                            div className = "form" > {
                                Form
                            } <
                            /div> < /
                            Col > <
                            /Row> <
                            Row >
                            <
                            Col className = "paymentBills" >
                            <
                            h2 > Bills < /h2> <
                            BillList bills = {
                                this.props.bills
                            }
                            /> < /
                            Col > <
                            /Row> < /
                            Container > <
                            Footer / >
                            <
                            /div>)
                        }

                        render() {
                            if (this.props.user == null || this.props.user == undefined) {
                                return ( < div > < /div>)
                                }
                                user = this.props.user
                                processingFunction = this.props.paymentProcessing
                                fetchPayments = this.props.getPayments
                                fetchBills = this.props.getBills
                                if (user.email.includes(`${process.env.REACT_APP_EMAIL}`)) {
                                    return this.buildProviderBilling()
                                } else {
                                    return this.buildPatientPayments()
                                }

                            }
                        }

                        export default PaymentForm