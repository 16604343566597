const loading = (state = {
    isLoading: false
}, action) => {
    switch (action.type) {
        case 'TOGGLE_LOADING':
            return Object.assign({}, state, {
                isLoading: action.show
            })
        default:
            return state
    }
}

export default loading