import React from 'react'
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  Form,
  FormGroup
} from 'reactstrap';
import swal from 'sweetalert';
import Footer from '../Footer'
import Header from '../../containers/NavContainer'

const Login = (props) => (
  <div>
    <Header isHome={true} />
    <div className="center-form">
      <Form onSubmit={props.onSubmit}>
        <FormGroup>
          <Label for="loginEmail">Email</Label>
          <Input type="email" name="email" id="loginEmail" placeholder="Your Email" />
        </FormGroup>
        <FormGroup>
          <Label for="loginPassword">Password</Label>
          <Input type="password" name="password" id="loginPassword" placeholder="" />
        </FormGroup>
        <FormGroup>
          <Button className="btn confirm" type="submit">Submit</Button>
          <Button className="btn" onClick={() => {
            const email = document.getElementById('loginEmail').value
            if (!email) {
              swal({
                title: "Missing Info",
                text: "Please provide your email",
                icon: "error",
              });
              return
            }
            props.reset(email)
          }}>Reset Password</Button>
        </FormGroup>
      </Form>
    </div>
    <Footer />
  </div>
)

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default Login
