import moment from 'moment-timezone';

const requests = (state = {
    list: [],
    isEditing: []
}, action) => {
    switch (action.type) {
        case 'GET_APPOINTMENT_REQUESTS':
            if (!action.requests) {
                return Object.assign({}, state, {
                    list: []
                })
            }

            let requests = action.requests
            requests.sort((a, b) => {
                let aStart = moment.tz(a.date, a.timezone).clone().tz(moment.tz.guess())
                let bStart = moment.tz(b.date, b.timezone).clone().tz(moment.tz.guess())
                return bStart.isSameOrBefore(aStart)
            })
            return Object.assign({}, state, {
                list: requests
            })
        case 'ADD_REQUEST':
            if (state.list) {
                return Object.assign({}, state, {
                    list: state.list.concat(action.request)
                })
            } else {
                return Object.assign({}, state, {
                    list: [action.request]
                })
            }
        case 'EDIT_REQUEST':
            return Object.assign({}, state, {
                isEditing: state.isEditing.concat(action.requestID)
            })
        case 'CANCEL_EDIT_REQUEST':
            return Object.assign({}, state, {
                isEditing: state.isEditing.filter((val) => val !== action.requestID)
            })
        case 'DELETE_REQUEST':
            if (state.list) {
                return Object.assign({}, state, {
                    list: state.list.filter(e => e.id !== action.request.id)
                })
            } else {
                return Object.assign({}, state, {
                    list: []
                })
            }
        case 'REQUEST_SUBMITTING':
            return Object.assign({}, state, {
                isSubmitting: action.isSubmitting
            })
        default:
            return state
    }
}

export default requests