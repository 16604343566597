import { connect } from 'react-redux'
import {
    toggleMessageModal,
    sendMessage,
    fetchMessages,
    selectedCnvo,
    sendInAppMessage,
} from '../actions'
import Messaging from '../components/Messaging'
const mapStateToProps = state => {
    let isProvider = false
    if (state.user.profile != undefined && state.user.profile.email.includes('@anywhere.healthcare')) {
        isProvider = true
    }
    return {
        user: state.user.profile,
        patients: state.patients,
        providers: state.providers,
        messages: state.messages,
        messageModal: state.modals.messageModal,
        isProvider
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        toggleMessageModal: reciepient => {
            dispatch(toggleMessageModal(reciepient))
        },
        sendMessage: (message, user, reciepient, isProvider) => {
            if (isProvider) {
                reciepient.name = `${reciepient.firstname} ${reciepient.lastname}`
                reciepient.id = reciepient.uid
            } else {
                user.name = `${user.firstname} ${user.lastname}`
            }
            dispatch(sendInAppMessage(message, user, reciepient, isProvider))
        },
        getMessages: (user, isProvider) => {
            dispatch(fetchMessages(user, isProvider))
        },
        selectedConversation: (id, name) => {
            dispatch(selectedCnvo(id, name))
            ownProps.history.push('/messaging-list')
        }
    }
}

const MessagingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Messaging)

export default MessagingContainer