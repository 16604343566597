import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AppointmentAuditList from '../components/AppointmentAuditList'
import { fetchAppointments } from '../actions'

class AppointmentAuditListContainer extends React.Component {

    constructor(props) {
        super(props);
        const { dispatch } = props;

        this.boundActionCreators = bindActionCreators(fetchAppointments, dispatch)
    }

    componentDidMount() {
        let { dispatch } = this.props
        let action = fetchAppointments(this.props.user)
        dispatch(action)
    }

    render() {
        let { appointments, isProvider, updateAppointment, user } = this.props
        return <AppointmentAuditList appointments={appointments} updateAppointment={updateAppointment}
            isProvider={isProvider} user={user}
            {...this.boundActionCreators} />
    }
}

export default connect(
    state => ({ appointments: state.appointments.audits, user: state.user.current })
)(AppointmentAuditListContainer)