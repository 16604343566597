const wordpress = (state = {
  posts: [],
}, action) => {
  switch (action.type) {
    case 'GET_POSTS':
      return Object.assign({}, state, {
        posts: action.posts
      })
    default:
      return state
  }
}

export default wordpress