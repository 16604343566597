const errors = (state = {
  user: undefined
}, action) => {
  switch (action.type) {
    case 'USER_ERROR':
      return Object.assign({}, state, {
        user: action.isError
      })
    default:
      return state
  }
}

export default errors