import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardDeck, CardHeader
} from 'reactstrap';
import moment from 'moment-timezone';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import './Appointments.css'

const AppointmentAuditList = (props) => {
    if (props.appointments !== null && props.appointments !== undefined) {
        return (<div>
            <CardDeck>
                {props.appointments.sort((a, b) => {
                    let startA = moment.tz(a.event.start.dateTime, a.event.start.timezone)
                    let startB = moment.tz(b.event.start.dateTime, b.event.start.timezone)

                    if (startA.isBefore(startB)) {
                        return -1
                    } else if (startA.isAfter(startB)) {
                        return 1
                    } else {
                        return 0
                    }
                }).map(appointment => {
                    let startDate = undefined
                    let onStartRequestChange = (val) => {
                        startDate = moment(val).toISOString();
                    }
                    let endDate = undefined
                    let onEndRequestChange = (val) => {
                        endDate = moment(val).toISOString();
                    }

                    const updateAppointment = () => {
                        let newStart = startDate
                        let newEnd = endDate
                        appointment.audit = {}
                        if (newStart !== undefined && newStart !== null) {
                            appointment.audit.start = newStart
                            appointment.audit.timezone = moment.tz.guess()
                        }
                        if (newEnd !== undefined && newEnd !== null) {
                            appointment.audit.end = newEnd
                        }

                        props.updateAppointment(appointment, props.user)
                    }
                    let start = moment.tz(appointment.event.start.dateTime, appointment.event.start.timezone).clone().tz(moment.tz.guess())
                    let end = moment.tz(appointment.event.end.dateTime, appointment.event.end.timezone).clone().tz(moment.tz.guess())
                    return (
                        <Card key={appointment.id}>
                            <CardHeader className="heading"><h3>{appointment.patient.name}</h3></CardHeader>
                            <CardBody>
                                <CardTitle className="sub-heading">{appointment.event.summary}</CardTitle>
                                <CardText>{appointment.event.description}</CardText>
                                <CardText>Listed Start Time: {start.format('MMM Do YYYY h:mm a')}</CardText>
                                <CardText>Listed End Time: {end.format('MMM Do YYYY h:mm a')}</CardText>
                                <div>
                                    <div>
                                        <label htmlFor="startDate">Confirm Start Date and Time</label>
                                        <DateTimePicker
                                            class="startDate"
                                            name="startDate"
                                            onChange={onStartRequestChange}
                                            format="DD MMM YYYY hh:mm a"
                                            time={true}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor="endDate">Confirm End Date and Time</label>
                                        <DateTimePicker
                                            class="endDate"
                                            name="endDate"
                                            onChange={onEndRequestChange}
                                            format="DD MMM YYYY hh:mm a"
                                            time={true}
                                        />
                                    </div>
                                </div>
                                <button className="btn" onClick={() => { updateAppointment() }}>Confirm date and times</button>
                            </CardBody>
                        </Card>
                    )
                })}
            </CardDeck>
        </div>)
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default AppointmentAuditList