import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardDeck, CardHeader
} from 'reactstrap';
import moment from 'moment-timezone';
import './PaymentList.css'

const arrangePayments = (payments, bills) => {
    let arranged = []
    let noMatch = []
    for (let payment of payments) {
        let index = indexOfBill(payment.bill_id, bills)
        if (index >= 0) {
            arranged[index] = payment
        } else {
            noMatch.push(payment)
        }
    }
    arranged.concat(noMatch)
    return arranged.filter(Boolean)
}

const indexOfBill = (id, bills) => {
    let index = -1
    for (let bill in bills) {
        if (bills[bill].id === id) {
            index = bill
        }
    }
    return index
}

const PaymentList = (props) => {
    if (props.payments !== null && props.payments !== undefined) {
        let sortedBills = props.bills.sort((a, b) => {
            if (a.due.dateTime < b.due.dateTime) {
                return -1;
            }
            if (a.due.dateTime > b.due.dateTime) {
                return 1;
            }
            return 0;
        })
        return (
            <CardDeck>

                {arrangePayments(props.payments, sortedBills).map(payment => {
                    let date = moment.tz(payment.date.dateTime, payment.date.dateTime.timezone).clone().tz(moment.tz.guess())
                    return (
                        <Card key={payment.id}>
                            <CardBody>
                                <CardTitle className="heading">Amount: ${payment.amount}</CardTitle>
                                <CardText>{payment.note}</CardText>
                                <CardText>Date Made: {date.format('MMM Do YYYY')}</CardText>
                            </CardBody>
                        </Card>
                    )
                })}
            </CardDeck>)
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default PaymentList