import React from 'react'
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card, CardText, CardBody,
  CardTitle
} from 'reactstrap';
import { Link } from 'react-router-dom'
import strings from '../../strings'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './Home.css'

const Home = (props) => (
  <div>
    <Header isHome={true} />
    <Container fluid={true} className="home">
      <Row className="splashRow">
        <Col>
          <img className="splashImage" src={process.env.REACT_APP_SPLASH} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>{strings.companySlogan}</h1>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <CardTitle>Enter Provider Portal</CardTitle>
            <button className="btn" onClick={props.providerSignIn}>
              Here
            </button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Enter Patient Portal</CardTitle>
            <Link className="btn" to="/login">Here</Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Patient Sign Up</CardTitle>
            <Link className="btn" to="/choose-provider">Here</Link>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Provider Sign Up</CardTitle>
            <a className="btn" href="https://forms.gle/mhiwG5KR8U4BFByG8">Here</a>
          </CardBody>
        </Card>
      </Row>
    </Container>
    <Footer />
  </div>
)

Home.propTypes = {
  providerSignIn: PropTypes.func.isRequired,
}

export default Home
