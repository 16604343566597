import moment from 'moment-timezone';

const messages = (state = {
  list: [],
  isEditing: [],
  created: false,
  selected: undefined,
  selectedMessages: []
}, action) => {
  switch (action.type) {
    case 'GET_MESSAGES':
      if (!action.messages) {
        return Object.assign({}, state, {
          list: []
        })
      }

      let messages = action.messages
      let convos = []
      for (let message of messages) {
        let mapped = convos.map(function (e) { return e.id })
        let idToUse = action.isProvider ? message.patient : message.provider
        let index = mapped.indexOf(idToUse)
        if (index >= 0) {
          let start = moment.tz(message.dateTime, message.timezone).clone().tz(moment.tz.guess())
          let oldMoment = moment.tz(convos[index].dateTime, convos[index].timezone).clone().tz(moment.tz.guess())
          convos[index].list.push(message)
          if (start.isSameOrAfter(oldMoment)) {
            convos[index].dateTime = message.dateTime
            convos[index].timezone = message.timezone
          }
        } else {
          convos.push({
            name: action.isProvider ? message.patientName : message.providerName,
            id: action.isProvider ? message.patient : message.provider,
            list: [message],
            dateTime: message.dateTime,
            timezone: message.timezone
          })
        }
      }
      convos.sort((a, b) => {
        let aStart = moment.tz(a.dateTime, a.timezone).clone().tz(moment.tz.guess())
        let bStart = moment.tz(b.dateTime, b.timezone).clone().tz(moment.tz.guess())
        if (aStart.isSameOrBefore(bStart)) {
          return -1
        } else {
          return 1
        }
      })
      for (let convo of convos) {
        convo.list.sort((a, b) => {
          let aStart = moment.tz(a.dateTime, a.timezone).clone().tz(moment.tz.guess())
          let bStart = moment.tz(b.dateTime, b.timezone).clone().tz(moment.tz.guess())
          if (aStart.isSameOrBefore(bStart)) {
            return -1
          } else {
            return 1
          }
        })
      }
      return Object.assign({}, state, {
        list: convos
      })
    case 'ADD_MESSAGE':
      if (state.list) {
        return Object.assign({}, state, {
          list: state.list.concat(action.request)
        })
      } else {
        return Object.assign({}, state, {
          list: [action.request]
        })
      }
    case 'SELECTED_CONVO':
      let messagesList
      if (state.list.length > 0 && action.name !== undefined) {
        let index = state.list.map(function (e) { return e.id }).indexOf(action.id)
        state.list[index].list.sort((a, b) => {
          let aStart = moment.tz(a.dateTime, a.timezone).clone().tz(moment.tz.guess())
          let bStart = moment.tz(b.dateTime, b.timezone).clone().tz(moment.tz.guess())
          return bStart.isSameOrBefore(aStart)
        })
        messagesList = state.list[index].list
      } else {
        messagesList = []
      }
      return Object.assign({}, state, {
        selected: { id: action.id, name: action.name },
        selectedMessages: messagesList
      })
    case 'EDIT_MESSAGE':
      return Object.assign({}, state, {
        isEditing: state.isEditing.concat(action.requestID)
      })
    case 'CANCEL_EDIT_MESSAGE':
      return Object.assign({}, state, {
        isEditing: state.isEditing.filter((val) => val !== action.requestID)
      })
    case 'MESSAGE_DELETED':
      if (state.list) {
        const filtered = state.selectedMessages.filter(e => e.id !== action.messageID)
        return Object.assign({}, state, {
          selectedMessages: filtered
        })
      } else {
        return Object.assign({}, state, {
          list: []
        })
      }
    case 'MESSAGE_SUBMITTING':
      return Object.assign({}, state, {
        isSubmitting: action.isSubmitting
      })
    case 'MESSAGE_CREATED':
      return Object.assign({}, state, {
        created: action.created
      })
    default:
      return state
  }
}

export default messages