import React from 'react'
import { Field, reduxForm } from 'redux-form'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import DropdownList from 'react-widgets/lib/DropdownList'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('en')
momentLocalizer()

const times = [{
  time: "Unavailable",
  value: -1
}, {
  time: "12:00am",
  value: 0
}, {
  time: "1:00am",
  value: 1
}, {
  time: "2:00am",
  value: 2
}, {
  time: "3:00am",
  value: 3
}, {
  time: "4:00am",
  value: 4
}, {
  time: "5:00am",
  value: 5
}, {
  time: "6:00am",
  value: 6
}, {
  time: "7:00am",
  value: 7
}, {
  time: "8:00am",
  value: 8
}, {
  time: "9:00am",
  value: 9
}, {
  time: "10:00am",
  value: 10
}, {
  time: "11:00am",
  value: 11
}, {
  time: "12:00pm",
  value: 12
}, {
  time: "1:00pm",
  value: 13
}, {
  time: "2:00pm",
  value: 14
}, {
  time: "3:00pm",
  value: 15
}, {
  time: "4:00pm",
  value: 16
}, {
  time: "5:00pm",
  value: 17
}, {
  time: "6:00pm",
  value: 18
}, {
  time: "7:00pm",
  value: 19
}, {
  time: "8:00pm",
  value: 20
}, {
  time: "9:00pm",
  value: 21
}, {
  time: "10:00pm",
  value: 22
},
{
  time: "11:00pm",
  value: 23
}]

const mapping = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

let availableTimes = []
let schedule
let day
const determineAvailableTimes = () => {
  if (!schedule || day === undefined) {
    availableTimes = []
  } else {
    let dayName = mapping[day]
    const dayValue = schedule[dayName]
    if (dayValue === undefined) {
      availableTimes = times
    } else {
      let start = dayValue.start
      let end = dayValue.end
      if (start == -1 || end == -1) {
        availableTimes = [{
          time: "Unavailable",
          value: -1
        }]
      } else {
        const filtered = times.filter((time) => {
          return time.value >= start && time.value <= end
        })
        availableTimes = filtered
      }
    }

  }
}


const validate = values => {
  const errors = {}
  if (!values.patient || values.patient.length === 0) {
    errors.patient = 'Required'
  }
  if (!values.date) {
    errors.date = 'Required'
  }
  if (!values.duration) {
    errors.duration = 'Required'
  }
  if (!values.title) {
    errors.title = 'Required'
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.time) {
    errors.time = 'Required'
  } else if (values.time.value < 8) {
    errors.time = 'Not a valid time'
  }


  return errors
}

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
const dateChanged = (value, onChange) => {
  day = moment(value).day()
  onChange(value)
  determineAvailableTimes()
}
const renderDateTimePicker = ({ input: { onChange, value }, showTime, meta: { touched, error, warning } }) => (
  <div>
    < DateTimePicker
      onChange={(value) => { dateChanged(value, onChange) }}
      format="DD MMM YYYY"
      time={false}
      value={!value ? null : new Date(value)}
    />
    {touched &&
      ((error && <span>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
)
const providerChanged = (value, onChange) => {
  schedule = value.value.schedule
  determineAvailableTimes()
  onChange(value)
}

const renderTimeDropdownList = ({ input, valueField, textField, meta: { touched, error, warning } }) => {
  return (<div>
    <DropdownList {...input}
      data={availableTimes}
      valueField={valueField}
      textField={textField}
      onChange={input.onChange} />
    {touched &&
      ((error && <span>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>)
}

const renderDropdownList = ({ input, data, valueField, textField, meta: { touched, error, warning } }) => {
  return (<div>
    <DropdownList {...input}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={(value) => { providerChanged(value, input.onChange) }} />
    {touched &&
      ((error && <span>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>)
}

const RequestForm = props => {
  const { handleSubmit, createEvent, pristine, reset, isSubmitting, providers, user, resetSubmit } = props
  const providerList = []
  if (providers.list === undefined) {
    return (
      <div>Error</div>
    )
  }
  for (let provider of providers.list) {
    providerList.push({
      name: provider.name,
      value: provider
    })
  }

  return (
    <div>
      <Header isProvider={false} />
      <div className="center-form">
        <form onSubmit={handleSubmit((data) => {
          createEvent(data, user)
        })} >
          <Field
            name="title"
            component={renderField}
            type="text"
            placeholder="Title"
            label="Purpose"
          />
          <Field
            name="description"
            component={renderField}
            type="text"
            placeholder="Description"
            label="Additional Information"
          />
          <div>
            <label>Provider</label>
            <Field
              name="provider"
              component={renderDropdownList}
              data={providerList}
              valueField="value"
              textField="name" />
          </div>
          <div>
            <label>Date</label>
            <Field
              name="date"
              showTime={false}
              component={renderDateTimePicker}
            />
          </div>
          <div>
            <label>Time</label>
            <Field
              name="time"
              component={renderTimeDropdownList}
              valueField="value"
              textField="time"
            />
          </div>
          <Field name="duration" type="number" component={renderField} label="Duration (mins)" />
          <div>
            <button className="btn confirm" type="submit" disabled={pristine || isSubmitting}>
              Submit
        </button>
            <button className="btn" type="button" onClick={() => {
              reset()
              resetSubmit()
            }}>
              Reset
        </button>
            <button className="btn cancel" type="button" disabled={isSubmitting} onClick={() => { window.location.href = "/" }}>
              Cancel
        </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default reduxForm({
  form: 'requestForm', // a unique identifier for this form
  validate
})(RequestForm)