import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AppointmentList from '../components/AppointmentList'
import { fetchAppointments } from '../actions'

class AppointmentListContainer extends React.Component {

    constructor(props) {
        super(props);
        const { dispatch } = props;

        this.boundActionCreators = bindActionCreators(fetchAppointments, dispatch)
    }

    componentDidMount() {
        let { dispatch } = this.props
        let action = fetchAppointments(this.props.user)
        dispatch(action)
    }

    render() {
        let { appointments, isProvider, toggleMessageModal, deleteAppointment } = this.props
        return <AppointmentList appointments={appointments}
            isProvider={isProvider} toggleMessageModal={toggleMessageModal} deleteAppointment={deleteAppointment}
            {...this.boundActionCreators} />
    }
}

export default connect(
    state => ({ appointments: state.appointments })
)(AppointmentListContainer)