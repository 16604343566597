import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PostsList from '../components/PostsList'
import {
    fetchPosts
} from '../actions'

class WordPressContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let { fetchPosts } = this.props
        fetchPosts()
    }

    render() {
        let { posts, selectPost, user } = this.props
        console.log("USER")
        console.log(user)
        let isProvider = false
        if (user && user.current && user.current.email.includes(`${process.env.REACT_APP_EMAIL}`)) {
            isProvider = true
        }
        return <PostsList posts={posts} isProvider={isProvider}
            selectPost={selectPost}
            isProvider={isProvider}
            {...this.boundActionCreators} />
    }
}

export default connect(
    state => ({ posts: state.wordpress.posts, user: state.user }),
    (dispatch, ownProps) => {
        return {
            fetchPosts: () => {
                let action = fetchPosts()
                dispatch(action)
            }
        }
    }
)(WordPressContainer)