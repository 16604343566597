import { connect } from 'react-redux'
import moment from 'moment-timezone';
import {
    toggleMessageModal,
    sendAppointmentMessage,
    updateRequest,
    editRequest,
    cancelEditRequest,
    updateAppointment
} from '../actions'
import Provider from '../components/Provider'

const buildEvent = (request, user, createEvent) => {
    let names = request.patientName.split(' ')
    const data = {
        title: request.title,
        description: request.description,
        date: moment(request.date).toISOString(),
        duration: request.duration,
        patient: {
            value: {
                email: request.patientEmail,
                firstname: names[0],
                lastname: names[1],
                uid: request.patientUID
            }
        }
    }
    createEvent(data, user)
}

const mapStateToProps = state => {
    return {
        user: state.user.current,
        patients: state.patients,
        appointments: state.appointments,
        messageModal: state.modals.messageModal,
        requests: state.requests
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        toggleMessageModal: (uid, appointment, isProvider) => {
            dispatch(toggleMessageModal(uid, appointment, isProvider))
        },
        sendMessage: (message, patient, provider, appointment, isProvider) => {
            dispatch(sendAppointmentMessage(message, patient, provider, appointment, isProvider))
        },
        updateRequest: (request, user, isConfirmation) => {
            if (request.patientConfirmed === true && isConfirmation) {
                request.providerConfirmed = true
                request.scheduled = true
                dispatch(updateRequest(request, user, false))
                buildEvent(request, user, ownProps.createEvent)
            } else {
                dispatch(updateRequest(request, user, true, isConfirmation))
            }
        },
        updateAppointment: (appointment, user) => {
            dispatch(updateAppointment(appointment, user))
        },
        editRequest: (reuqestID) => {
            dispatch(editRequest(reuqestID))
        },
        cancelEdit: (requestID) => {
            dispatch(cancelEditRequest(requestID))
        }
    }
}

const ProviderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Provider)

export default ProviderContainer