import React from 'react'
import {
    Container,
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './Provider.css'
import AppointmentList from '../../containers/AppoinmentListContainer'
import AppointmentAuditList from '../../containers/AppoinmentAuditListContainer'
import RequestList from '../RequestList'

const ProviderComp = (props) => {
    return (
        <div>
            <Header logout={props.logout} isProvider={true} />

            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Hello {props.user.displayName}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="fusion-login">
                        <h2>Creating Freedom for Providers and Access for Patients</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="appointments">
                        <h2>Upcoming Appointments </h2>
                        <Link to="/create-appointment" className="btn">Create Appointment</Link>
                        <AppointmentList user={props.user} appointments={props.appointments} deleteAppointment={props.deleteAppointment}
                            isProvider={true} toggleMessageModal={props.toggleMessageModal} />
                    </Col>
                </Row>
                <Row>
                    <Col className="requests">
                        <h2>Appointment Requests</h2>
                        <RequestList user={props.user} requests={props.requests} deleteRequest={props.deleteRequest} editRequest={props.editRequest} cancelEdit={props.cancelEdit}
                            isProvider={true} updateRequest={props.updateRequest} createEvent={props.createEvent} />

                    </Col>
                </Row>
                <Row>
                    <Col className="appointmentAudits">
                        <h2>Appointments Requiring Confirmation</h2>
                        <AppointmentAuditList user={props.user} appointments={props.appointments}
                            isProvider={true} updateAppointment={props.updateAppointment} />

                    </Col>
                </Row>
            </Container>
            <Modal isOpen={props.messageModal.active} toggle={() => { props.toggleMessageModal(null) }}>
                <ModalHeader toggle={() => { props.toggleMessageModal(null) }}>Send Message</ModalHeader>
                <ModalBody>
                    <p>Message:</p>
                    <textarea id="messageModalText" name="messageModalText" maxLength="160" type="text" placeholder="Enter Your Message">
                    </textarea>
                </ModalBody>
                <ModalFooter>
                    <Button className="confirm" onClick={() => {
                        let message = document.getElementById("messageModalText").value
                        props.sendMessage(message, props.messageModal.uid, props.user.uid, props.messageModal.appointment, props.messageModal.isProvider)
                    }}>Send Message</Button>{' '}
                    <Button className="cancel" onClick={() => { props.toggleMessageModal(null) }}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Footer />
        </div>
    )
}

export default ProviderComp