import { processingPayment } from "./actions";

const config = {
    firebase: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
        vapid: process.env.REACT_APP_FIREBASE_VAPID
    },
    google: {
        clientid: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        discoverydocs: [],
        scopes: "",
        hostDomian: process.env.REACT_APP_GOOGLE_HOST_DOMAIN,
        calendarkey: process.env.REACT_APP_GOOGLE_CAL_KEY
    },
    functions: {
        host: process.env.REACT_APP_FUNCTION_HOST
    },
    wordpress: {
        host: process.env.REACT_APP_WORDPRESS_HOST
    },
    square: {
        applicationId: process.env.REACT_APP_SQUARE_APP,
        location_id: process.env.REACT_APP_SQUARE_LOCATION
    }
}
export default config