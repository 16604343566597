import { connect } from 'react-redux'
import App from '../components/App'

const mapStateToProps = state => {
  return {
    user: state.user,
    errors: state.errors
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ownProps
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer