import moment from 'moment-timezone';
import swal from 'sweetalert';
import config from '../config'
import { errorHandler } from './errorHandler'
export const toggleNav = () => {
  return {
    type: 'TOGGLE_NAV'
  }
}
export const toggleLoading = (show) => {
  return {
    type: 'TOGGLE_LOADING',
    show
  }
}
export const toggleMessageModal = (person, appointment, isProvider) => {
  return {
    type: 'TOGGLE_MESSAGE_MODAL',
    person,
    appointment,
    isProvider
  }
}

export const toggleEmailModal = (patient) => {
  return {
    type: 'TOGGLE_EMAIL_MODAL',
    patient
  }
}

export const processingPayment = processing => {
  return {
    type: 'PROCESSING_PAYMENT',
    processing
  }
}
export const appointmentSubmitting = isSubmitting => {
  return {
    type: 'APPOINTMENT_SUBMITTING',
    isSubmitting
  }
}

export const requestSubmitting = isSubmitting => {
  return {
    type: 'REQUEST_SUBMITTING',
    isSubmitting
  }
}

export const addUser = user => {
  return {
    type: 'ADD_USER',
    user
  }
}

export const queueProvider = provider => {
  return {
    type: 'QUEUE_PROVIDER',
    provider
  }
}

export const currentState = state => {
  return {
    type: 'CURRENT_STATE',
    state
  }
}

export const providerSelected = provider => {
  return {
    type: 'PROVIDER_SELECTED',
    provider
  }
}

export const removeUser = user => {
  return {
    type: 'REMOVE_USER',
    user
  }
}

export const setProfile = profile => {
  return {
    type: 'SET_PROFILE',
    profile
  }
}

export const clearProfile = () => {
  return {
    type: 'CLEAR_PROFILE'
  }
}

export const setVisibilityFilter = filter => {
  return {
    type: 'SET_VISIBILITY_FILTER',
    filter
  }
}

export const toggleTodo = id => {
  return {
    type: 'TOGGLE_TODO',
    id
  }
}

export const getAppointments = appointments => {
  return {
    type: 'GET_APPOINTMENTS',
    appointments
  }
}

export const getPosts = posts => {
  return {
    type: 'GET_POSTS',
    posts
  }
}

export const getRequests = requests => {
  return {
    type: 'GET_APPOINTMENT_REQUESTS',
    requests
  }
}

export const editRequest = requestID => {
  return {
    type: 'EDIT_REQUEST',
    requestID
  }
}

export const cancelEditRequest = requestID => {
  return {
    type: 'CANCEL_EDIT_REQUEST',
    requestID
  }
}

export const getPayments = payments => {
  return {
    type: 'GET_PAYMENTS',
    payments
  }
}

export const getBills = bills => {
  return {
    type: 'GET_BILLS',
    bills
  }
}

export const addAppointment = appointment => {
  return {
    type: 'ADD_APPOINTMENT',
    appointment
  }
}

export const deleteAppointment = appointment => {
  return {
    type: 'DELETE_APPOINTMENT',
    appointment
  }
}

export const deleteRequest = request => {
  return {
    type: 'DELETE_REQUEST',
    request
  }
}

export const getPatients = patients => {
  return {
    type: 'GET_PATIENTS',
    patients
  }
}

export const getProviders = providers => {
  return {
    type: 'GET_PROVIDERS',
    providers
  }
}

export const fetchPatients = user => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getPatients?uid=${user.uid}`,
      {
        headers: {
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getPatients(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchProviders = (state, country, company) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    let params = new URLSearchParams();
    if (country) {
      params.append('country', country)
    }
    if (state) {
      params.append('state', state)
    }
    if (company) {
      params.append('company', company)
    }
    fetch(`${config.functions.host}/getProviders?${params}`,
      {
        headers: {
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getProviders(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchUser = user => {
  var authProvider
  if (user.providerData !== undefined) {
    authProvider = user.providerData[0].providerId
  }
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getUser?uid=${user.uid}&authProvider=${authProvider}`,
      {
        headers: {
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        if (res.ok) {
          res.json().then((val) => {
            dispatch(userError(false))
            if (val.country && val.provider) {
              dispatch(fetchProviders(val.state, val.country.code, val.provider.company))
            } if (val.country) {
              dispatch(fetchProviders(val.state, val.country.code))
            } else {
              dispatch(fetchProviders(val.state, undefined))
            }
            dispatch(setProfile(val));
          }).catch((err) => {
            dispatch(toggleLoading(false))
            console.log(err)
          })
        } else {
          dispatch(userError(true))
        }
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const userError = isError => {
  return {
    type: 'USER_ERROR',
    isError
  }
}

export const fetchPatientAppointments = user => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getPatientAppointments`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
        body: JSON.stringify(user)
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(getAppointments(res));
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchPatientAppointmentRequests = user => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getPatientAppointmentRequest?patientUID=${user.uid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET"
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getRequests(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const updateRequest = (request, user, isProvider, isConfirmation) => {
  if (isConfirmation) {
    if (isProvider) {
      request.providerConfirmed = true
    } else {
      request.patientConfirmed = true
    }
  }
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/updateAppointmentRequest`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'PUT'
        },
        mode: 'cors',
        method: "PUT",
        body: JSON.stringify({ request })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        if (isProvider) {
          dispatch(fetchProviderAppointmentRequests(user));
        } else {
          dispatch(fetchPatientAppointmentRequests(user));
        }
        dispatch(fetchAppointments(user))
        dispatch(cancelEditRequest(request.id))
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
        errorHandler("Network Error", "There was an error updating your request.", "error")
      })

  };
}

export const createRequest = (request, user) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/addAppointmentRequest`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({ request })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(requestSubmitting(false))
        dispatch(fetchPatientAppointmentRequests(user));
        window.location = '/'
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
        dispatch(requestSubmitting(false))
        errorHandler("Network Error", "There was an error creating your request.", "error")
      })

  };
}

export const updatePatient = (user) => {
  return (dispatch) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/updatePatient`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'PUT'
        },
        mode: 'cors',
        method: "PUT",
        body: JSON.stringify({ user })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(fetchUser(user));
        window.location = '/'
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
        errorHandler("Network Error", "There was an error updating you provider.", "error")
      })

  };
}

export const updateAppointment = (appointment, user) => {
  return (dispatch) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/updateAppointment`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'PUT'
        },
        mode: 'cors',
        method: "PUT",
        body: JSON.stringify({ appointment })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(fetchAppointments(user))
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
        errorHandler("Network Error", "There was an error updating your appointment.", "error")
      })

  };
}

export const fetchAppointments = user => {
  var authProvider
  if (user.providerData !== undefined) {
    authProvider = user.providerData[0].providerId
  }
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getAppointments?uid=${user.uid}&authProvider=${authProvider}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getAppointments(val));
        }).catch((err) => {
          dispatch(toggleLoading(false))
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchPosts = () => {
  return (dispatch) => {
    dispatch(toggleLoading(true))
    fetch(`${config.wordpress.host}/wp-json/wp/v2/posts`,
      {
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getPosts(val));
        }).catch((err) => {
          dispatch(toggleLoading(false))
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchProviderAppointmentRequests = user => {
  var authProvider
  if (user.providerData !== undefined) {
    authProvider = user.providerData[0].providerId
  }
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getProviderRequests?providerID=${user.uid}&authProvider=${authProvider}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getRequests(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchPayments = user => {
  var uid = user.uid
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/getPayments?uid=${uid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getPayments(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const fetchBills = (user, isProvider) => {
  const uid = user.uid
  const path = isProvider ? "getProviderBills?provider_uid" : "getPatientBills?patient_uid"
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/${path}=${uid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET",
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getBills(val));
        }).catch((err) => {
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
      })

  };
}

export const sendAppointmentMessage = (message, receipient, sender, appointment, isProvider) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    let path = isProvider ? 'messagePatientAppointment' : 'messageProviderAppointment'
    fetch(`${config.functions.host}/${path}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({
          providerUID: isProvider ? sender : receipient,
          patientUID: isProvider ? receipient : sender,
          appointmentID: appointment,
          message
        })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(messageSent(null, res));
        dispatch(fetchAppointments(getState().user.current));
      })
      .catch(function (err) {
        dispatch(toggleLoading(false))
        dispatch(messageSent(err, null));
      })

  };
}

export const sendMessage = (message, provider_id, providerName, patient_uid, patientName) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/addMessage`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({
          providerID: provider_id,
          providerName,
          patientUID: patient_uid,
          patientName,
          message
        })
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        dispatch(messageSent(null, res));
        dispatch(fetchAppointments(getState().user.current));
      })
      .catch(function (err) {
        dispatch(toggleLoading(false))
        dispatch(messageSent(err, null));
      })

  };
}

export const sendInAppMessage = (message, user, receipient, isProvider) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    let providerName
    let patientName
    let providerID
    let patientUID
    if (isProvider) {
      providerName = user.name
      patientName = receipient.name
      providerID = user.id
      patientUID = receipient.id
    } else {
      patientName = user.name
      providerName = receipient.name
      patientUID = user.uid
      providerID = receipient.id
    }
    fetch(`${config.functions.host}/addMessage`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'POST'
        },
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({
          providerName,
          "dateTime": moment().toISOString(),
          patientName,
          patientUID,
          providerID,
          "message": message,
          "timezone": moment.tz.guess(),
          "sender": isProvider ? "provider" : "patient"
        })
      })
      .then(function (res) {
        swal({
          title: "Message Sent",
          text: "Your message was sent Successfully",
          icon: "success",
        });
        dispatch(toggleLoading(false))
        dispatch(messageCreated(true))
        dispatch(fetchMessages(getState().user.current, isProvider))
      })
      .catch(function (err) {
        swal({
          title: "Error Sending Message",
          text: "There was an issue sending your message",
          icon: "error",
        });
        dispatch(toggleLoading(false))
        dispatch(messageCreated(false))
      })

  };
}

export const selectedCnvo = (id, name) => {
  return {
    type: 'SELECTED_CONVO',
    id,
    name
  }
}

export const messageSent = (err, res) => {
  return {
    type: 'MESSAGE_SENT',
    err,
    res
  }
}

export const messageCreated = created => {
  return {
    type: 'MESSAGE_CREATED',
    created
  }
}

export const deleteMessage = messageID => {
  return (dispatch, getState) => {
    fetch(`${config.functions.host}/deleteMessage`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'PUT'
        },
        mode: 'cors',
        method: "DELETE",
        body: JSON.stringify({
          messageID
        })
      })
      .then(function (res) {
        dispatch(messageDeleted(messageID))
      })
      .catch(function (err) {
        console.log("token failed")
        console.log(err)
      })
  }
}

export const messageDeleted = messageID => {
  return {
    type: 'MESSAGE_DELETED',
    messageID
  }
}

export const purgeAll = () => {
  return {
    type: 'PURGE_ALL'
  }
}

export const updateFCMToken = (uid, token, isProvider) => {
  return (dispatch, getState) => {
    fetch(`${config.functions.host}/updateWebFCMToken`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'PUT'
        },
        mode: 'cors',
        method: "PUT",
        body: JSON.stringify({
          uid,
          token,
          isProvider
        })
      })
      .then(function (res) {
        console.log("token set")
      })
      .catch(function (err) {
        console.log("token failed")
        console.log(err)
      })

  };
}

export const fetchMessages = (user, isProvider) => {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    let path = isProvider ? `getProviderMessages?provider_uid=${user.uid}` : `getPatientMessages?patient_uid=${user.uid}`
    fetch(`${config.functions.host}/${path}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
        },
        mode: 'cors',
        method: "GET"
      })
      .then(function (res) {
        dispatch(toggleLoading(false))
        res.json().then((val) => {
          dispatch(getMessages(val, isProvider));
        }).catch((err) => {
          dispatch(toggleLoading(false))
          console.log(err)
        })
      })
      .catch(function (res) {
        dispatch(toggleLoading(false))
        console.error(res)
      })

  };
}

export const getMessages = (messages, isProvider) => {
  return {
    type: 'GET_MESSAGES',
    messages,
    isProvider
  }
}

export const selectProviderCountry = country => {
  return {
    type: 'SELECT_PROVIDER_COUNTRY',
    country
  }
}

export const selectProviderState = state => {
  return {
    type: 'SELECT_PROVIDER_STATE',
    state
  }
}

export const selectProviderCompany = company => {
  return {
    type: 'SELECT_PROVIDER_COMPANY',
    company
  }
}