import { combineReducers } from 'redux'
import appointments from './appointments'
import errors from './errors'
import user from './users'
import patients from './patients'
import modals from './modals'
import payment from './payments'
import providers from './providers'
import requests from './requests'
import nav from './nav'
import loading from './loading'
import wordpress from './wordpress'
import messages from './messages'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'

export default (history) => combineReducers({
  router: connectRouter(history),
  user,
  appointments,
  patients,
  form: formReducer,
  modals,
  payment,
  providers,
  requests,
  nav,
  loading,
  wordpress,
  messages,
  errors
});

