import { connect } from 'react-redux'
import moment from 'moment-timezone';
import Convos from '../components/MessageList'
import {
    fetchMessages,
    sendInAppMessage,
    deleteMessage
} from '../actions'
const mapStateToProps = state => {
    let isProvider = false
    if (state.user.profile != undefined && state.user.profile.email.includes(`${process.env.REACT_APP_EMAIL}`)) {
        isProvider = true
    }
    return {
        user: state.user.profile,
        messages: state.messages.selectedMessages,
        selected: state.messages.selected,
        isProvider
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reply: (text, user, selected, isProvider) => {
            if (!isProvider) {
                user.name = `${user.firstname} ${user.lastname}`
            }
            dispatch(sendInAppMessage(text, user, selected, isProvider))
        },
        delete: messageID => {
            dispatch(deleteMessage(messageID))
        },
        getMessages: (user, isProvider) => {
            dispatch(fetchMessages(user, isProvider))
        }
    }
}

const MessagesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Convos)

export default MessagesContainer