import { connect } from 'react-redux'
import ProviderProfile from '../components/ProviderProfile'
const mapStateToProps = state => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ownProps
}

const ProviderProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderProfile)

export default ProviderProfileContainer