import { connect } from 'react-redux'
import LoadingSpinner from '../components/LoadingSpinner'

const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const LoadingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadingSpinner)

export default LoadingContainer