/* global gapi */
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import moment from 'moment-timezone';
import * as mom from 'moment'
import swal from 'sweetalert';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'bootstrap/dist/css/bootstrap.css';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './containers/AppContainer';
import Login from './containers/LoginContainer';
import Messaging from './containers/MessagingContainer';
import MessagingList from './containers/MessageListContainer';
import AppointmentContainer from './containers/AppointmentContainer';
import WordPressContainer from './containers/WordPressContainer';
import RequestContainer from './containers/RequestContainer'
import PaymentForm from './containers/PaymentContainer'
import LoadingContainer from './containers/LoadingContainer'
import PostContainer from './containers/PostContainer'
import SignUp from './components/SignUp';
import Profile from './components/Profile';
import ProviderProfileContainer from './containers/ProviderProfileContainer';
import ProviderSelector from './containers/ProviderSelectForm'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import { Route } from 'react-router'
import registerServiceWorker from './registerServiceWorker'
import { countries } from './countries'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'

import createRootReducer from './reducers'
import {
    addUser,
    removeUser,
    fetchPatients,
    fetchUser,
    appointmentSubmitting,
    deleteAppointment,
    deleteRequest,
    fetchPayments,
    fetchBills,
    fetchPatientAppointmentRequests,
    fetchProviderAppointmentRequests,
    fetchProviders,
    addAppointment,
    toggleLoading,
    purgeAll,
    updateFCMToken,
    queueProvider
} from './actions'

import config from './config'
let gapi = window.gapi
while (gapi === undefined) { }
// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history)

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating

const composeEnhancers =
    typeof window === 'object' &&
        process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(middleware, thunk),
);

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const store = createStore(persistedReducer, enhancer)
let persistor = persistStore(store)

var firebaseConfig = config.firebase;
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Client ID and API key from the Developer Console
var CLIENT_ID = config.google.clientid;

//Notifications
let messaging
let requestToken
let getToken
if (('serviceWorker' in navigator) && ('PushManager' in window)) {
    // Service Workers are not supported. Return
    messaging = firebase.messaging();
    messaging.usePublicVapidKey(firebaseConfig.vapid);

    requestToken = (uid, isProvider) => {
        messaging.requestPermission().then(function () {
            getToken(uid, isProvider)
        }).catch(function (err) {
            console.log('Unable to get permission to notify.', err);
        });
    }

    getToken = (uid, isProvider) => {
        messaging.getToken().then(function (currentToken) {
            if (currentToken) {
                store.dispatch(updateFCMToken(uid, currentToken, isProvider))
            } else {
                requestToken(uid, isProvider)
            }
        }).catch(function (err) {
            console.log('An error occurred while retrieving token. ', err);
        });
    }

    messaging.onMessage(function (payload) {
        console.log('Message received. ', payload);
        // ...
    });
} else {
    getToken = (uid, isProvider) => {
        console.log("notifications not supported")
    }
}
store.dispatch(fetchProviders(null, "US"))
// Array of API discovery doc URLs for APIs used by the quickstart
// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
var GoogleAuth
const handleClientLoad = () => {
    gapi.load('client:auth2', initClient);
}
const initClient = () => {
    gapi.client.init({
        clientId: CLIENT_ID,
        scope: 'profile',
        hosted_domain: config.google.hostDomian
    }).then(function (val) {
        GoogleAuth = gapi.auth2.getAuthInstance();

        // Listen for sign-in s"tate changes.
        GoogleAuth.isSignedIn.listen(updateSigninStatus);

        // Handle initial sign-in state. (Determine if user is already signed in.)
        var user = GoogleAuth.currentUser.get();
        updateSigninStatus();
    }).catch((err) => {
        console.log(err)
    });
}
handleClientLoad()
const auth = firebaseApp.auth(); //the firebase auth namespace
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
var provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/plus.login')
provider.setCustomParameters({
    hd: config.google.hostDomian
});

function updateSigninStatus() {
    var googleUser = gapi.auth2.getAuthInstance().currentUser.get()
    var isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get()
    if (isSignedIn) {
        // get the credentials from the google auth response
        var idToken = googleUser.getAuthResponse().id_token;
        var creds = firebase.auth.GoogleAuthProvider.credential(idToken);
        // auth in the user 
        firebase.auth().signInAndRetrieveDataWithCredential(creds).then((userCred) => {
            let user = userCred.user
            if (user) {
                fetch(`${config.functions.host}/addProvider`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        mode: 'cors',
                        method: "POST",
                        body: JSON.stringify(user)
                    })
                    .then(function (res) {
                    })
                    .catch(function (res) { })
            }
        })
    }
}


var patient = new firebase.auth.GoogleAuthProvider();
patient.addScope('https://www.googleapis.com/auth/plus.login');

const storageKey = 'USER_STORAGE';

const providerSignIn = (history) => {
    GoogleAuth.signIn();
}

const patientSignUp = (value) => {
    const email = value.email
    const password = value.password
    const user = value
    user.password = undefined
    user.confirmPassword = undefined
    let birthday
    if (value.birthday < 10) {
        birthday = `0${value.birthday}`
    } else {
        birthday = value.birthday
    }
    let birthmonth
    if (value.birthmonth < 10) {
        birthmonth = `0${value.birthmonth}`
    } else {
        birthmonth = value.birthmonth
    }
    const dateString = `${value.birthyear}-${birthmonth}-${birthday}`
    user.birthdate = moment(dateString).toISOString()
    auth.createUserWithEmailAndPassword(email, password).then((res) => {
        const firebaseUser = res.user
        let state = store.getState()
        if (state.providers.queued !== undefined) {
            user.provider = state.providers.queued
            store.dispatch(queueProvider(undefined))
        }
        user.uid = firebaseUser.uid
        firebaseUser.updateProfile({
            displayName: `${user.firstname} ${user.lastname}`
        }).then(function () {
        }, function (error) {
        });
        store.dispatch(toggleLoading(true))
        fetch(`${config.functions.host}/addPatient`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Method': 'POST'
                },
                mode: 'cors',
                method: "POST",
                body: JSON.stringify({ user })
            })
            .then(function (res) {
                store.dispatch(toggleLoading(false))
                if (res.ok) {
                    store.dispatch(fetchUser(user))
                    history.push('/')
                } else {
                    swal({
                        title: "Error Signing Up",
                        text: "There was an error in the sign up process",
                        icon: "error",
                    });
                }

            })
            .catch(function (res) {
                store.dispatch(toggleLoading(false))
            })
    }).catch(function (error) {
        store.dispatch(toggleLoading(false))
        // Handle Errors here.
        var errorMessage = error.message;
        swal({
            title: "Error Signing Up",
            text: errorMessage,
            icon: "error",
        });
    });
}

const providerSignUp = (user, value) => {
    value.uid = user.current.uid
    let birthday
    if (value.birthday < 10) {
        birthday = `0${value.birthday}`
    } else {
        birthday = value.birthday
    }
    let birthmonth
    if (value.birthmonth < 10) {
        birthmonth = `0${value.birthmonth}`
    } else {
        birthmonth = value.birthmonth
    }
    let country = value.country
    const countryList = countries
    if (country.code == undefined) {
        value.country = countryList.find(element => element.name === country)
    }
    const dateString = `${value.birthyear}-${birthmonth}-${birthday}`
    value.birthdate = moment(dateString).toISOString()
    const payload = { provider: value }
    fetch(`${config.functions.host}/updateProvider`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'PUT'
            },
            mode: 'cors',
            method: "PUT",
            body: JSON.stringify(payload)
        })
        .then(function (res) {
            if (res.ok) {
                store.dispatch(fetchUser(user.current))
                history.push('/')
            } else {
                swal({
                    title: "Error Signing Up",
                    text: "There was an error in the sign up process",
                    icon: "error",
                });
            }
        })
        .catch(function (error) {
            // Handle Errors here.
            var errorMessage = error.message;
            swal({
                title: "Error Signing Up",
                text: errorMessage,
                icon: "error",
            });
        });
}

const updatePassword = (value) => {
    const password = value.password
    const user = value
    store.dispatch(toggleLoading(true))
    auth.currentUser.updatePassword(password).then((res) => {
        store.dispatch(toggleLoading(false))
        swal({
            title: "Password Updated!",
            text: "Your password has been changed",
            icon: "success",
        });
    }).catch(function (error) {
        store.dispatch(toggleLoading(false))
        // Handle Errors here.
        var errorMessage = error.message;
        swal({
            title: "Error Updating Password",
            text: errorMessage,
            icon: "error",
        });
    });
}

const updateSchedule = (value) => {
    let schedule = {
        monday: {
            start: value.monday_start.value,
            end: value.monday_end.value
        },
        tuesday: {
            start: value.tuesday_start.value,
            end: value.tuesday_end.value
        },
        wednesday: {
            start: value.wednesday_start.value,
            end: value.wednesday_end.value
        },
        thursday: {
            start: value.thursday_start.value,
            end: value.thursday_end.value
        },
        friday: {
            start: value.friday_start.value,
            end: value.friday_end.value
        },
        saturday: {
            start: value.saturday_start.value,
            end: value.saturday_end.value
        },
        sunday: {
            start: value.sunday_start.value,
            end: value.sunday_end.value
        },
    }
    store.dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/updateSchedule`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'PUT'
            },
            mode: 'cors',
            method: "PUT",
            body: JSON.stringify({ uid: auth.currentUser.uid, schedule })
        })
        .then(function (res) {
            store.dispatch(toggleLoading(false))
            if (res.ok) {
                swal({
                    title: "Schedule Updated!",
                    text: "Your changes have been saved",
                    icon: "success",
                });
            } else {
                swal({
                    title: "Error Updating Schedule",
                    text: "There was an error saving your schedule info",
                    icon: "error",
                });
            }

        })
        .catch(function (error) {
            store.dispatch(toggleLoading(false))
            // Handle Errors here.
            var errorMessage = error.message;
            swal({
                title: "Error Updating Schedule",
                text: errorMessage,
                icon: "error",
            });
        });
}

const updateProfile = (value) => {
    const user = value

    user.password = undefined
    user.confirmPassword = undefined
    user.uid = auth.currentUser.uid
    store.dispatch(toggleLoading(true))
    auth.currentUser.updateProfile({
        displayName: `${user.firstname} ${user.lastname}`
    }).then(function () {
        fetch(`${config.functions.host}/addPatient`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Method': 'POST'
                },
                mode: 'cors',
                method: "POST",
                body: JSON.stringify({ user })
            })
            .then(function (res) {
                store.dispatch(toggleLoading(false))
                if (res.ok) {
                    swal({
                        title: "Profile Updated!",
                        text: "Your changes have been saved",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: "Error Updating Profile",
                        text: "There was an error saving your profile info",
                        icon: "error",
                    });
                }

            })
            .catch(function (error) {
                store.dispatch(toggleLoading(false))
                // Handle Errors here.
                var errorMessage = error.message;
                swal({
                    title: "Error Updating Profile",
                    text: errorMessage,
                    icon: "error",
                });
            });
    }, function (error) {
    });
}

const resetPassword = (email) => {
    auth.sendPasswordResetEmail(email).then(function () {
        swal({
            title: "Email Reset Link Sent",
            text: "Check your email to reset your password",
            icon: "success",
        });
    }).catch(function (error) {
        swal({
            title: "Error Resetting Password",
            text: error,
            icon: "error",
        });
    });
}

const resetSubmit = () => {
    store.dispatch(appointmentSubmitting(false))
}

const createEvent = (value, user) => {
    let start = moment(value.date)
    let email = user.email
    let patientName = value.patient.name
    let patientEmail = value.patient.email || value.patient.value.email
    let providerName = user.displayName
    var event = {
        'summary': value.title,
        'location': '',
        'description': value.description,
        'sendNotifications': true,
        'start': {
            'dateTime': start.toISOString(),
            'timeZone': moment.tz.guess()
        },
        'end': {
            'dateTime': start.add(value.duration, 'm').toISOString(),
            'timeZone': moment.tz.guess()
        },
        'attendees': [
            { 'email': email }
        ],
        'reminders': {
            'useDefault': false,
            'overrides': [
                { 'method': 'email', 'minutes': 24 * 60 },
                { 'method': 'popup', 'minutes': 10 }
            ]
        }
    };

    fetch(`${config.functions.host}/serviceAppointment`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            mode: 'cors',
            method: "POST",
            body: JSON.stringify({ email, event, patientEmail, patientName, providerName })
        })
        .then(function (res) {
            if (res.status != 500) {
                res.json().then(data => {
                    // do something with your data
                    postAppointment(data.data, user, value.patient.value)
                });

            } else {
                swal({
                    title: "Error Creating Appointment",
                    text: "We couldn't create your appointment at this time",
                    icon: "error",
                });
            }

        })
        .catch(function (err) {
            swal({
                title: "Error Creating Appointment",
                text: "We couldn't create your appointment at this time",
                icon: "error",
            });
        })
}

const postAppointment = (event, provider, patient) => {
    let appointment = {
        "provider": {
            uid: provider.uid,
            name: provider.displayName
        },
        "patient": {
            uid: patient.uid,
            name: `${patient.firstname} ${patient.lastname}`
        },
        event
    }
    store.dispatch(toggleLoading(true))
    store.dispatch(appointmentSubmitting(true))
    fetch(`${config.functions.host}/addAppointment`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            mode: 'cors',
            method: "POST",
            body: JSON.stringify({ appointment })
        })
        .then(function (res) {
            store.dispatch(toggleLoading(false))
            if (res.ok) {
                //ppointmentEmail(appointment)
                store.dispatch(addAppointment(appointment))
                store.dispatch(appointmentSubmitting(false))
                history.push('/')
            } else {
                store.dispatch(appointmentSubmitting(false))
            }
        })
        .catch(function (res) {
            store.dispatch(toggleLoading(false))
            store.dispatch(appointmentSubmitting(false))
        })
}

const deletingAppointment = (appointment) => {
    store.dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/deleteAppointment`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'DELETE'
            },
            mode: 'cors',
            method: "DELETE",
            body: JSON.stringify({ appointment })
        })
        .then(function (res) {
            store.dispatch(toggleLoading(false))
            if (res.ok) {

                store.dispatch(deleteAppointment(appointment))

            } else {
                swal({
                    title: "Error Deleting Appointment",
                    text: "Unable to delete.",
                    icon: "error",
                });
            }
        })
        .catch(function (error) {
            store.dispatch(toggleLoading(false))
            swal({
                title: "Error Deleting Appointment",
                text: error,
                icon: "error",
            });
        })
}

const deletingRequest = (request) => {
    store.dispatch(toggleLoading(true))
    fetch(`${config.functions.host}/deleteRequest`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'DELETE'
            },
            mode: 'cors',
            method: "DELETE",
            body: JSON.stringify({ request })
        })
        .then(function (res) {
            store.dispatch(toggleLoading(false))
            store.dispatch(deleteRequest(request))

        })
        .catch(function (error) {
            store.dispatch(toggleLoading(false))
            swal({
                title: "Error Deleting Appointment",
                text: error,
                icon: "error",
            });
        })
}

auth.onAuthStateChanged(user => {
    if (user) {
        window.localStorage.setItem(storageKey, user.uid);
        store.dispatch(addUser(user))
        store.dispatch(fetchUser(user))
        store.dispatch(fetchPayments(user))
        if (user.providerData[0].providerId === "google.com") {
            let action = fetchPatients(user)
            store.dispatch(action)
            store.dispatch(fetchBills(user, true))
            store.dispatch(fetchProviderAppointmentRequests(user))
            getToken(user.uid, true)
        } else {
            store.dispatch(fetchBills(user, false))
            store.dispatch(fetchPatientAppointmentRequests(user))
            getToken(user.uid, false)
        }
    } else {
        window.localStorage.removeItem(storageKey);
        store.dispatch(removeUser(user))
        persistor.purge()
        store.dispatch(purgeAll())
    }
});

const sendAppointmentEmail = (appointment) => {
    const patient = appointment.patient.name
    const provider = appointment.provider.name
    let emails = appointment.event.attendees.map((val) => {
        return val.email
    })
    let emailString = ''
    for (let email of emails) {
        emailString += `${email},`
    }

    let start = moment.tz(appointment.event.start.dateTime, moment.tz.guess()).format('MMMM Do, h:mm a')
    let message = `Your appointment with ${patient} and ${provider} on ${start} has been scheduled.\n
            Click this link to join when your appointment starts: ${appointment.event.hangoutLink}`
    let headers_obj = {
        'To': emailString,
        'Subject': "Appointment Scheduled"
    }
    var email = '';
    for (var header in headers_obj)
        email += header += ": " + headers_obj[header] + "\r\n";
    email += "\r\n" + message;
    var sendRequest = gapi.client.gmail.users.messages.send({
        'userId': 'me',
        'resource': {
            'raw': window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
        }
    });
    sendRequest.execute((data) => {
        store.dispatch(toggleLoading(false))
        if (data.labelIds !== undefined && data.labelIds.includes("SENT")) {
        } else {
        }
    });
}

function logout() {
    GoogleAuth.signOut()
    auth.signOut()
    window.location = "/"
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <div className="app-container">
                    <Route exact path="/" render={() => <App providerSignUp={providerSignUp} deleteAppointment={deletingAppointment}
                        deleteRequest={deletingRequest} providerSignIn={providerSignIn} createEvent={createEvent} onProfileSubmit={updateProfile}
                        onPasswordSubmit={updatePassword} onScheduleSubmit={updateSchedule}
                        logout={() => { logout() }} />} />
                    <Route exact path="/login" render={() => <Login auth={auth} history={history} reset={resetPassword} />} />
                    <Route exact path="/signUp" render={() => <SignUp onSubmit={patientSignUp} />} />
                    <Route exact path="/choose-provider" render={() => <ProviderSelector />} />
                    <Route exact path="/create-appointment" render={() => <AppointmentContainer createEvent={createEvent} resetSubmit={resetSubmit} logout={() => { logout() }} />} />
                    <Route exact path="/create-request" render={() => <RequestContainer logout={() => { logout() }} />} />
                    <Route exact path="/view-post" render={() => <PostContainer logout={() => { logout() }} />} />
                    <Route exact path="/profile" render={() => <Profile onProfileSubmit={updateProfile} onPasswordSubmit={updatePassword} onScheduleSubmit={updateSchedule} logout={() => { logout() }} />} />
                    <Route exact path="/preferences" render={() => <ProviderProfileContainer providerSignUp={providerSignUp} onScheduleSubmit={updateSchedule} logout={() => { logout() }} />} />
                    <Route exact path="/messaging" render={() => <Messaging history={history} logout={() => { logout() }} />} />
                    <Route exact path="/messaging-list" render={() => <MessagingList history={history} logout={() => { logout() }} />} />
                    <Route exact path="/payments" render={() => <PaymentForm logout={() => { logout() }} />} />
                    <LoadingContainer />
                </div>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('healthcare-root')
)


//registerServiceWorker();
