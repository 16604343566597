import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import DropdownList from 'react-widgets/lib/DropdownList'

import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './CustomForm.css'


const validate = values => {
  const errors = {}

  return errors
}

const renderDropdownList = ({ input, data, valueField, textField }) => {
  return (<DropdownList {...input}
    data={data}
    valueField={valueField}
    textField={textField}
    onChange={input.onChange} />)
}

const renderError = ({ meta: { touched, error } }) => touched && error ?
  <span>{error}</span> : false

const renderField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, warning }
}) => {
  return (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={placeholder} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
}

const cancel = () => {
  window.location = "/"
}

let ProviderSelectForm = props => {
  const { handleSubmit, submitting, initialValues,
    providerList, user, formSubmit, states, countries, companies,
    selectedCountry, selectedState, selectedCompany, changeCountry, changeState, changeCompany } = props
  return (
    <div>
      <Header isHome={true} />
      <h1> Please Select Your Provider </h1>
      <form onSubmit={handleSubmit((val) => formSubmit(val, user))}>
        <div>
          <label>Country</label>
          <Field
            name="country"
            component={renderDropdownList}
            data={countries}
            valueField="value"
            textField="name"
            defaultValue={selectedCountry}
            onChange={(val) => {
              changeCountry(val.code)
            }} />
        </div>
        <div>
          <label>State</label>
          <Field
            name="state"
            component={renderDropdownList}
            data={states}
            defaultValue={selectedState}
            onChange={(val) => {
              let state = `${val[0]}${val[1]}`
              changeState(state)
            }} />
        </div>
        <div>
          <label>Company</label>
          <Field
            name="company"
            component={renderDropdownList}
            data={companies}
            defaultValue={selectedCompany}
            valueField="name"
            textField="name"
            onChange={(val) => {
              changeCompany(val.name)
            }} />
        </div>
        <div>
          <label>Provider</label>
          <Field
            name="provider"
            component={renderDropdownList}
            data={providerList}
            valueField="value"
            textField="name" />
        </div>
        <div>
          <button type="submit" disabled={submitting}>
            Submit
        </button>
          <button type="button" disabled={submitting} onClick={cancel}>
            Cancel
        </button>
        </div>
      </form>
      <Footer />
    </div>
  )
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProviderSelectForm = reduxForm({
  form: 'providerSelectForm', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  enableReinitialize: true
})(ProviderSelectForm)

// You have to connect() to any reducers that you wish to connect to yourself
ProviderSelectForm = connect(
  state => {
    if (state.user.provider === undefined || state.user.provider === null) {
      return {
        initialValues: {}
      }
    } else {
      return {
        initialValues: {
          provider: state.user.provider,
          country: state.providers.country,
          state: state.providers.state
        }
      }
    }

  }
)(ProviderSelectForm)

export default ProviderSelectForm