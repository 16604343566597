import React from 'react'
import './Footer.css'


const Footer = () => (
  <footer className="footer">
    <a href="https://firebasestorage.googleapis.com/v0/b/pt-telemedicine.appspot.com/o/Anywhere%20Health%20Privacy%20Policy%20and%20Terms%20of%20Service.pdf?alt=media&token=69ae03c4-3985-48f3-b949-8cea48fabf2e">
      Terms of Service and Privacy Policy
  </a>
    <a href="https://firebasestorage.googleapis.com/v0/b/pt-telemedicine.appspot.com/o/Anywhere%20Health%20Provider%20Oath.pdf?alt=media&token=fbd7cb96-30b8-4dde-bca6-6fcdc2697809">
      Our Provider Oath
  </a>
    <a href={`mailto:info${process.env.REACT_APP_EMAIL}`}>
      Contact Us
  </a>
  </footer>
)

export default Footer