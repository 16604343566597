import React from 'react'
import {
    Container,
    Row,
    Col,
    UncontrolledAlert,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Link } from 'react-router-dom'
import swal from 'sweetalert';
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import AppointmentList from '../../containers/AppoinmentListContainer'
import RequestList from '../RequestList'
import moment from 'moment'
let hasSeenBillAlert = false
const PatientComp = (props) => {
    for (let bill of props.bills) {
        if (!bill.paid && moment().isAfter(bill.due) && hasSeenBillAlert) {
            hasSeenBillAlert = true
            swal({
                title: "Pay Bill",
                text: "You have one or more outstanding bills. Please visit the payments section to make a payment.",
                icon: "info",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                        className: "btn cancel"
                    },
                    confirm: {
                        text: "Pay Bill",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true,
                        className: "btn"
                    }
                }
            }).then((confirmed) => {
                if (confirmed) {
                    window.location = "/payments"
                }
            })
            break
        }
    }
    return (
        <div>
            <Header logout={props.logout} />
            <Container fluid={true}>
                <UncontrolledAlert color="info">
                    <p>
                        If using this app on your mobile device, please download the google hangouts app <a href="http://onelink.to/8vsgb8" target="_blank" rel="noopener noreferrer" className="alert-link">here</a>. It will be used for your video appoinments.
     </p>
                </UncontrolledAlert>
                <Row>
                    <Col>
                        <h1>Hello {props.user.displayName}</h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h2>Upcoming Appointments </h2>
                        <AppointmentList user={props.user} deleteAppointment={props.deleteAppointment} appointments={props.appointments} toggleMessageModal={props.toggleMessageModal} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>Appointment Requests</h2>
                        <Link to="/create-request" className="btn">Request an Appointment</Link>
                        <RequestList user={props.user} deleteRequest={props.deleteRequest} requests={props.requests} cancelEdit={props.cancelEdit} editRequest={props.editRequest} updateRequest={props.updateRequest} />
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={props.messageModal.active} toggle={() => { props.toggleMessageModal(null) }}>
                <ModalHeader toggle={() => { props.toggleMessageModal(null) }}>Send Message</ModalHeader>
                <ModalBody>
                    <p>Message:</p>
                    <textarea id="messageModalText" name="messageModalText" maxLength="160" type="text" placeholder="Enter Your Message">
                    </textarea>
                </ModalBody>
                <ModalFooter>
                    <Button className="confirm" onClick={() => {
                        let message = document.getElementById("messageModalText").value
                        props.sendMessage(message, props.messageModal.uid, props.user.uid, props.messageModal.appointment, props.messageModal.isProvider)
                    }}>Send Message</Button>{' '}
                    <Button className="cancel" onClick={() => { props.toggleMessageModal(null) }}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Footer />
        </div>
    )
}

export default PatientComp