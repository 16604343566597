import { connect } from 'react-redux'
import uniqby from 'lodash.uniqby'
import ProviderSelectForm from '../components/ProviderSelectForm'
import {
  updatePatient,
  selectProviderCountry,
  selectProviderState,
  selectProviderCompany,
  queueProvider
} from '../actions'

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

const mapStateToProps = state => {
  let user;
  if (state.user == undefined || state.user.profile == undefined) {
    user = undefined
  } else {
    user = state.user.profile
  }
  const allProviders = state.providers.list
  const countries = uniqby(allProviders.map((provider) => {
    return provider.country
  }).sort((a, b) => {
    if (a.code > b.code) {
      return 1
    } else if (a.code < b.code) {
      return -1
    } else {
      return 0
    }
  }), 'code')
  let states = []
  if (state.providers.country != null) {
    states = Array.from(new Set(allProviders.filter((provider) => { return provider.country.code === state.providers.country }).map((provider) => {
      return provider.state
    }))).sort((a, b) => {
      if (a > b) {
        return 1
      } else if (a < b) {
        return -1
      } else {
        return 0
      }
    })
  }
  let companies = []
  if (state.providers.country != null && state.providers.state != null) {
    companies = Array.from(new Set(allProviders
      .filter((provider) => { return provider.country.code === state.providers.country })
      .filter((provider) => { return provider.state === state.providers.state })
      .map((provider) => {
        return { name: provider.company }
      })))
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      })
  }
  companies = companies.filter((company, index, self) =>
    index === self.findIndex((c) => (
      c.name === company.name
    ))
  )
  let filteredProviders = allProviders
  if (state.providers.country != null) {
    filteredProviders = filteredProviders.filter((provider) => {
      return provider.country.code == state.providers.country
    })
  }
  if (state.providers.state != null) {
    filteredProviders = filteredProviders.filter((provider) => {
      return provider.state == state.providers.state
    })
  }
  if (state.providers.company != null) {
    filteredProviders = filteredProviders.filter((provider) => {
      return provider.company == state.providers.company
    })
  }
  return {
    allProviders: state.providers.list,
    providerList: filteredProviders,
    user,
    countries,
    states,
    companies,
    selectedCountry: state.providers.country,
    selectedState: state.providers.state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    formSubmit: (val, user) => {
      const provider = val.provider
      if (user) {
        user.provider = provider
        dispatch(updatePatient(user))
      } else {
        dispatch(queueProvider(provider))
      }
      if (!user || !user.uid) {
        window.location = "/signup"
      }
    },
    changeCountry: (val) => {
      dispatch(selectProviderCountry(val))
    },
    changeState: (val) => {
      dispatch(selectProviderState(val))
    },
    changeCompany: (val) => {
      dispatch(selectProviderCompany(val))
    },
  }
}

const ProviderSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderSelectForm)

export default ProviderSelectContainer