const patients = (state = {
    list: []
}, action) => {
    switch (action.type) {
        case 'GET_PATIENTS':
            if (!action.patients) {
                return Object.assign({}, state, {
                    list: []
                })
            }
            return Object.assign({}, state, {
                list: action.patients
            })
        default:
            return state
    }
}

export default patients