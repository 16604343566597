import { connect } from 'react-redux'
import PostDetail from '../components/PostDetail'
const mapStateToProps = state => {
  let post = state.routerReducer.location.state.post
  return {
    post,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const PostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostDetail)

export default PostContainer