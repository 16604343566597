import React from 'react'
import {
  connect
} from 'react-redux'
import {
  Field,
  reduxForm,
  formValueSelector
} from 'redux-form'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import DropdownList from 'react-widgets/lib/DropdownList'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import './CustomForm.css'
import { countries } from '../../countries'
moment.locale('en')
momentLocalizer()



const validate = values => {
  const errors = {}


  if (!values.birthmonth) {
    errors.birthmonth = 'Required'
  } else if (`${values.birthmonth.length}` > 2) {
    errors.birthmonth = "Invalid Month"
  } else if (values.birthmonth > 12 || values.birthmonth < 1) {
    errors.birthmonth = "Invalid Month"
  }
  if (!values.birthday) {
    errors.birthday = 'Required'
  } else if (`${values.birthday.length}` > 2) {
    errors.birthday = "Invalid Day"
  } else if (values.birthmonth > 31 || values.birthmonth < 1) {
    errors.birthday = "Invalid Day"
  }
  let parsedYear = parseInt(values.birthyear)
  if (!values.birthyear) {
    errors.birthyear = 'Required'
  } else if (parsedYear === NaN || parsedYear < 1900) {
    errors.birthyear = "Invalid Year"
  }

  if (!values.sex) {
    errors.sex = 'Required'
  }

  if (!values.state) {
    errors.state = 'Required'
  }

  if (!values.city) {
    errors.city = 'Required'
  }

  if (!values.zip) {
    errors.zip = 'Required'
  }

  if (!values.fields) {
    errors.fields = 'Required'
  }

  if (!values.company) {
    errors.company = 'Required'
  }

  if (!values.licenseNum) {
    errors.licenseNum = 'Required'
  }

  if (!values.licenseState) {
    errors.licenseState = 'Required'
  }
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!values.phone) {
    errors.phone = 'Required'
  } else if (!values.phone.match(phoneno)) {
    errors.phone = 'Phone number is not valid'
  }

  if (!values.country) {
    errors.country = 'Required'
  }

  if (values.country && values.country.code == 'US' && !values.state) {
    errors.state = 'Required'
  }

  if (!values.acceptedTerms) {
    errors.acceptedTerms = 'Required'
  }

  return errors
}


const renderError = ({
  meta: {
    touched,
    error
  }
}) => touched && error ?
    <span> {
      error
    } </span> : false

const normalizePhone = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`
}

const normalizeCity = value => {
  if (!value) {
    return value
  }
  const onlyLetters = value.replace(/[^a-zA-Z\s]/g, '')
  return onlyLetters.toUpperCase()
}
const normalizeState = value => {
  if (!value) {
    return value
  }

  const onlyLetters = value.replace(/[^a-zA-Z]/g, '')
  if (onlyLetters.length < 3) {
    return onlyLetters.toUpperCase()
  }
}
const normalizeDayMonth = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) {
    return onlyNums
  }
}
const normalizeYear = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 4) {
    return onlyNums
  }
}

const normalizeZip = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) {
    return onlyNums
  }
}

const renderField = ({
  input,
  label,
  type,
  placeholder,
  meta: {
    touched,
    error,
    warning
  }
}) => {
  return (<div>
    <label> {
      label
    } </label>
    <div>
      <input {
        ...input
      }
        placeholder={
          placeholder
        }
        type={
          type
        }
      /> {
        touched &&
        ((error && <span> {
          error
        } </span>) ||
          (warning && < span > {
            warning
          } </span>))} </div> </div>
  )
}

const renderDropdownList = ({
  input,
  data,
  valueField,
  textField,
  meta: {
    touched,
    error,
    warning
  }
}) => {
  return (<div>
    <DropdownList {
      ...input
    }
      data={
        data
      }
      valueField={
        valueField
      }
      textField={
        textField
      }
    /> {
      touched &&
      ((error && <span> {
        error
      } </span>) ||
        (warning && < span > {
          warning
        } </span>))} </div>)
}

const cancel = () => {
  window.location = "/"
}

let SignUpProviderForm = props => {
  const {
    handleSubmit,
    submitting,
    initialValues,
    countryValue
  } = props
  let header = undefined
  if (props.embedded === false) {
    header = (<Header isHome={
      true
    } />)
  }

  let footer = undefined
  if (props.embedded === false) {
    footer = (<Footer />)
  }
  return (<div>
    {header}

    <h1> Profile Information </h1>
    <form onSubmit={
      handleSubmit
    } >
      <Field name="birthmonth"
        type="number"
        component={
          renderField
        }
        label="Birth Month"
        normalize={
          normalizeDayMonth
        }
      /> <Field name="birthday"
        type="number"
        component={
          renderField
        }
        label="Birth Day"
        normalize={
          normalizeDayMonth
        }
      /> <Field name="birthyear"
        type="number"
        component={
          renderField
        }
        label="Birth Year"
        normalize={
          normalizeYear
        }
      />
      <div>
        <label> Sex </label>
        <div>
          <label> <Field name="sex"
            component="input"
            type="radio"
            value="male" /> Male </label>
          <label > <Field name="sex"
            component="input"
            type="radio"
            value="female" /> Female </label>
          <label>
            <Field name="sex"
              component="input"
              type="radio"
              value="other" /> Other </label> <
            Field name="sex"
            component={
              renderError
            }
          />
        </div >
      </div> <Field name="phone"
        component={
          renderField
        }
        type="text"
        placeholder="Phone Number"
        label="Phone Number"
        normalize={
          normalizePhone
        }
      />
      <Field name="company"
        component={
          renderField
        }
        type="text"
        placeholder="Company"
        label="Company"
      />
      <Field name="licenseNum"
        component={
          renderField
        }
        type="text"
        placeholder="License Number"
        label="License Number"
      />
      <Field name="licenseState"
        component={
          renderField
        }
        type="text"
        placeholder="License State"
        label="License State"
      />
      <Field name="fields"
        component={
          renderField
        }
        type="text"
        label="Field(s) of practice (Comma seperated)"
        placeholder="E.g. Physical therapy, pelvic health, Life Coaching"
      />
      <div>
        <label > Country </label>
        <Field name="country"
          component={
            renderDropdownList
          }
          data={
            countries
          }
          valueField="code"
          textField="name" />
      </div> {
        countryValue !== undefined && countryValue.code === 'US' && (< Field name="street"
          component={
            renderField
          }
          type="text"
          placeholder="Street Address"
          label="Street Address" />
        )
      } {
        countryValue !== undefined && countryValue.code === 'US' && (<
          Field name="city"
          component={
            renderField
          }
          type="text"
          placeholder="City"
          label="City"
          normalize={
            normalizeCity
          }
        />)} {
        countryValue !== undefined && countryValue.code === 'US' && (<
          Field name="state"
          component={
            renderField
          }
          type="text"
          placeholder="State"
          label="State"
          normalize={
            normalizeState
          }
        />)} {
        countryValue !== undefined && countryValue.code === 'US' && (<
          Field name="zip"
          component={
            renderField
          }
          type="number"
          placeholder="Zipcode"
          label="Zipcode"
          normalize={
            normalizeZip
          }
        />)}
      < Field name="acceptedTerms"
        id="acceptedTerms"
        label="I have read and agree to the terms and conditions and privacy policy below"
        component={
          renderField
        }
        type="checkbox" />
      <div className="termsSection" >
        <a href="https://firebasestorage.googleapis.com/v0/b/pt-telemedicine.appspot.com/o/Anywhere%20Health%20Privacy%20Policy%20and%20Terms%20of%20Service.pdf?alt=media&token=69ae03c4-3985-48f3-b949-8cea48fabf2e" >
          Terms of Service and Privacy Policy </a>
        <a href="https://firebasestorage.googleapis.com/v0/b/pt-telemedicine.appspot.com/o/Anywhere%20Health%20Provider%20Oath.pdf?alt=media&token=fbd7cb96-30b8-4dde-bca6-6fcdc2697809" >
          Our Provider Oath </a> </div> <div>
        <button type="submit"
          disabled={
            submitting
          } >
          Submit </button> <button type="button"
          disabled={
            submitting
          }
          onClick={
            cancel
          } >
          Cancel </button> </div >
    </form>
    {footer}
  </div>
  )
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SignUpProviderForm = reduxForm({
  form: 'signUpProviderForm', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  enableReinitialize: true
})(SignUpProviderForm)

// You have to connect() to any reducers that you wish to connect to yourself
const selector = formValueSelector('signUpProviderForm')

SignUpProviderForm = connect(
  state => {
    const countryValue = selector(state, 'country')
    if (state.user.profile === undefined || state.user.profile === null) {
      return {
        initialValues: {},
        countryValue
      }
    } else {
      return {
        initialValues: state.user.profile,
        countryValue
      }
    }

  }
)(SignUpProviderForm)

export default SignUpProviderForm