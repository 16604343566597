import React from 'react'
import { Field, reduxForm } from 'redux-form'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import DropdownList from 'react-widgets/lib/DropdownList'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('en')
momentLocalizer()


const validate = values => {
  const errors = {}
  if (!values.patient || values.patient.length === 0) {
    errors.patient = 'Required'
  }
  if (!values.date) {
    errors.date = 'Required'
  }
  if (!values.duration) {
    errors.duration = 'Required'
  }
  if (!values.title) {
    errors.title = 'Required'
  }
  if (!values.description) {
    errors.description = 'Required'
  }

  return errors
}

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )

const renderDateTimePicker = ({ input: { onChange, value }, showTime, meta: { touched, error, warning } }) => (
  <div>
    < DateTimePicker
      onChange={onChange}
      format="DD MMM YYYY hh:mm a"
      time={showTime}

      value={!value ? null : new Date(value)}
    />
    {touched &&
      ((error && <span>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
)

const renderDropdownList = ({ input, data, valueField, textField }) =>
  <DropdownList {...input}
    data={data}
    valueField={valueField}
    textField={textField}
    onChange={input.onChange} />

const AppointmentForm = props => {
  const { handleSubmit, createEvent, pristine, reset, isSubmitting, patients, user, resetSubmit } = props
  const patientList = []
  if (patients.list === undefined) {
    return (
      <div>Error</div>
    )
  }
  for (let patient of patients.list) {
    patientList.push({
      name: `${patient.firstname} ${patient.lastname}`,
      value: patient
    })
  }

  return (
    <div>
      <Header isProvider={true} />
      <form onSubmit={handleSubmit((data) => {
        createEvent(data, user)
      })} >
        <Field
          name="title"
          component={renderField}
          type="text"
          placeholder="Title"
          label="Title"
        />
        <Field
          name="description"
          component={renderField}
          type="text"
          placeholder="Description"
          label="Description"
        />
        <div>
          <label>Patient</label>
          <Field
            name="patient"
            component={renderDropdownList}
            data={patientList}
            valueField="value"
            textField="name" />
        </div>
        <div>
          <label>Date and Time</label>
          <Field
            name="date"
            showTime={true}
            component={renderDateTimePicker}
          />
        </div>
        <Field name="duration" type="number" component={renderField} label="Duration (mins)" />
        <div>
          <button className="btn confirm" type="submit" disabled={pristine || isSubmitting}>
            Submit
        </button>
          <button className="btn" type="button" onClick={() => {
            reset()
            resetSubmit()
          }}>
            Reset
        </button>
          <button className="btn cancel" type="button" disabled={isSubmitting} onClick={() => { window.location.href = "/" }}>
            Cancel
        </button>
        </div>
      </form>
      <Footer />
    </div>
  )
}

export default reduxForm({
  form: 'appointmentForm', // a unique identifier for this form
  validate
})(AppointmentForm)