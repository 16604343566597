import { connect } from 'react-redux'
import AppointmentForm from '../components/AppointmentForm'

const mapStateToProps = state => {
    return {
        patients: state.patients,
        user: state.user.current,
        isSubmitting: state.appointments.isSubmitting
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createEvent: ownProps.createEvent,
        resetSubmit: ownProps.resetSubmit
    }
}

const AppointmentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentForm)

export default AppointmentContainer