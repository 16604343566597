import React from 'react'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import SignUpProviderForm from '../SignUpProvider'
import ScheduleSelectForm from '../Schedule'
import './CustomForm.css'

const ProfilePage = props => {
  return (
    <div>
      <Header isHome={false} isProvider={true} />
      <h1 className="profile-header"> Change Preferences </h1>
      <div className="center-form">
        <SignUpProviderForm embedded={true} user={props.user} onSubmit={(values) => { props.providerSignUp(props.user, values) }} />
      </div>
      <div className="center-form">
        <h2 className="profile-subheader"> Set Schedule </h2>
        <ScheduleSelectForm {...props} />
      </div>
      <Footer />
    </div>
  )
}

export default ProfilePage