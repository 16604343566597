import { connect } from 'react-redux'
import Patient from '../components/Patient'
import {
    updateRequest,
    editRequest,
    cancelEditRequest,
    toggleMessageModal,
    sendAppointmentMessage
} from '../actions'
const mapStateToProps = state => {
    return {
        user: state.user.current,
        appointments: state.appointments,
        bills: state.payment.bills,
        requests: state.requests,
        messageModal: state.modals.messageModal,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleMessageModal: (uid, appointment, isProvider) => {
            dispatch(toggleMessageModal(uid, appointment, isProvider))
        },
        sendMessage: (message, receipient, sender, appointment, isProvider) => {
            dispatch(sendAppointmentMessage(message, receipient, sender, appointment, isProvider))
        },
        updateRequest: (request, user, isConfirmation) => {
            dispatch(updateRequest(request, user, false, isConfirmation))
        },
        editRequest: (reuqestID) => {
            dispatch(editRequest(reuqestID))
        },
        cancelEdit: (requestID) => {
            dispatch(cancelEditRequest(requestID))
        }
    }
}

const PatientContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Patient)

export default PatientContainer