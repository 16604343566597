import React from 'react'
import PropTypes from 'prop-types';
import './App.css'
import Loading from '../Loading'
import Profile from '../Profile';
import Provider from '../../containers/ProviderContainer'
import Patient from '../../containers/PatientContainer'
import ProviderSelectForm from '../../containers/ProviderSelectForm'
import SignUpProviderForm from '../SignUpProvider'
import Home from '../Home'


const App = (props) => {

  if (props.user === null || props.user === undefined) {
    return (
      <Home providerSignIn={props.providerSignIn} />
    )
  }
  const user = props.user
  const errors = props.errors
  if (user.current === undefined) {
    return (
      <Home providerSignIn={props.providerSignIn} />
    )
  }
  if (user.current.email.includes(`${process.env.REACT_APP_EMAIL}`)) {
    if (user.profile !== undefined && user.profile.profileComplete === true) {
      return (<Provider logout={props.logout} deleteRequest={props.deleteRequest} deleteAppointment={props.deleteAppointment} createEvent={props.createEvent} />)
    } else {
      return (<SignUpProviderForm embedded={false} user={user} onSubmit={(values) => { props.providerSignUp(props.user, values) }} />)
    }
  } else if (errors.user === true && user.current && !user.profile) {
    return (<Profile onProfileSubmit={() => { props.updateProfile() }} onPasswordSubmit={() => { props.updatePassword() }} onScheduleSubmit={() => { props.updateSchedule() }} logout={() => { props.logout() }} />)
  } else {
    if (user.profile === undefined) {
      return (
        <Loading />
      )
    } else if (user.profile.provider === null || user.profile.provider === undefined) {
      return (<ProviderSelectForm />)
    } else {
      return (<Patient logout={props.logout} deleteRequest={props.deleteRequest} deleteAppointment={props.deleteAppointment} />)
    }
  }
}


App.propTypes = {
  user: PropTypes.object,
}

export default App
