import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardDeck, CardHeader, Button
} from 'reactstrap';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import { generateICSFile } from '../../icsGenerator'
import './Appointments.css'

const showVideoPopUp = (hangoutLink) => {
    debugger;
    swal({
        title: "Heads Up!",
        text: "By clicking you agree that you are in a safe and secure location prior to beginning video appointment.",
        icon: "info",
        buttons: {
            cancel: {
                text: "Cancel",
                value: null,
                visible: true,
                className: "",
                closeModal: true,
            },
            confirm: {
                text: "I agree",
                value: true,
                visible: true,
                className: "",
                closeModal: true
            }
        }
    }).then((confirmed) => {
        if (confirmed) {
            window.open(
                hangoutLink,
                '_blank'
            );
        }
    })
}

const showCancellationPopUp = (appointment, deleteAppointment) => {
    swal({
        title: "Confirm Cancellation",
        text: "Are you sure you want to cancel this appointment? This action cannot be undone.",
        icon: "warning",
        dangerMode: true,
        buttons: {
            cancel: {
                text: "Do Not Cancel",
                value: null,
                visible: true,
                className: "",
                closeModal: true,
            },
            confirm: {
                text: "Confirm",
                value: true,
                visible: true,
                className: "",
                closeModal: true
            }
        }
    }).then((confirmed) => {
        if (confirmed) {
            deleteAppointment(appointment)
        }
    })
}

const renderMessages = messages => {
    if (messages === undefined || messages === null ||
        messages.length === 0) {
        return null
    } else {
        let formatted = messages.map(message => {
            return (<li>{message}</li>)
        })
        return (
            <div>
                <p>Messages Sent:</p>
                <ul>
                    {formatted}
                </ul>
            </div>
        )
    }
}

const EventButton = (appointment) => {
    return (
        <Button onClick={() => {
            const newEvent = {
                BEGIN: 'VCALENDAR',
                PRODID: appointment.event.hangoutLink,
                UID: appointment.event.iCalUID,
                CATEGORIES: 'APPOINTMENT',
                DTSTART: appointment.event.start.dateTime,
                DTEND: appointment.event.end.dateTime,
                SUMMARY: appointment.event.summary,
                DESCRIPTION: appointment.event.description,
                END: 'VCALENDAR',
            }
            generateICSFile(newEvent)
        }}>Create Calander Event</Button>
    )
}

const AppointmentList = (props) => {
    if (props.appointments.list !== null && props.appointments.list !== undefined) {
        let toggleMessageModal = (uid, appointment, isProvider) => {
            props.toggleMessageModal(uid, appointment, isProvider)
        }
        let PendingAppointments
        let pending = 0
        if (props.isProvider) {
            for (let appointment of props.appointments.list) {
                let start = moment.tz(appointment.event.start.dateTime, appointment.event.start.timezone).clone().tz(moment.tz.guess())
                let dayDiff = moment().diff(start, 'days')
                if (dayDiff == 0) {
                    pending += 1
                }
            }
            PendingAppointments = <h4>Appointments Remaining Today: {pending}</h4>
        }
        return (<div>
            {PendingAppointments}
            <CardDeck>
                {props.appointments.list.sort((a, b) => {
                    let startA = moment.tz(a.event.start.dateTime, a.event.start.timezone)
                    let startB = moment.tz(b.event.start.dateTime, b.event.start.timezone)

                    if (startA.isBefore(startB)) {
                        return -1
                    } else if (startA.isAfter(startB)) {
                        return 1
                    } else {
                        return 0
                    }
                }).map(appointment => {
                    let start = moment.tz(appointment.event.start.dateTime, appointment.event.start.timezone).clone().tz(moment.tz.guess())
                    let end = moment.tz(appointment.event.end.dateTime, appointment.event.end.timezone).clone().tz(moment.tz.guess())
                    let MessagePatient = null
                    let Messages = null
                    if (props.isProvider) {
                        MessagePatient = <button className="btn" onClick={() => { toggleMessageModal(appointment.patient.uid, appointment.id, true) }}>Message Patient</button>
                        Messages = <div>
                            {renderMessages(appointment.messages)}
                        </div>
                    } else {
                        MessagePatient = <button className="btn" onClick={() => { toggleMessageModal(appointment.provider.name, appointment.id, false) }}>Message Provider</button>
                        Messages = <div>
                            {renderMessages(appointment.messages)}
                        </div>
                    }
                    let CancelAppointment = <button className="cancel btn" onClick={() => { showCancellationPopUp(appointment, props.deleteAppointment) }}>Cancel Appointment</button>
                    return (
                        <Card key={appointment.id}>
                            <CardHeader className="heading"><h3>{appointment.patient.name}</h3></CardHeader>
                            <CardBody>
                                <CardTitle className="sub-heading">{appointment.event.summary}</CardTitle>
                                <CardText>{appointment.event.description}</CardText>
                                <CardText>Start Time: {start.format('MMM Do YYYY h:mm a')}</CardText>
                                <CardText>End Time: {end.format('MMM Do YYYY h:mm a')}</CardText>
                                <CardText><a href="#notify" onClick={() => { showVideoPopUp(appointment.event.hangoutLink) }}>Video Call Link</a> </CardText>
                                {EventButton(appointment)}
                                {MessagePatient}
                                {CancelAppointment}
                                {Messages}
                            </CardBody>
                        </Card>
                    )
                })}
            </CardDeck>
        </div>)
    } else {
        return (
            <div>
                <h2> Appointments </h2>
            </div>
        )
    }
}

export default AppointmentList