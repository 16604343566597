import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardDeck, CardHeader
} from 'reactstrap';
import moment from 'moment-timezone';
import swal from 'sweetalert';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import './Request.css'

const showCancellationPopUp = (request, deleteRequest) => {
    swal({
        title: "Confirm Cancellation",
        text: "Are you sure you want to cancel this request? This action cannot be undone.",
        icon: "warning",
        dangerMode: true,
        buttons: {
            cancel: {
                text: "Do Not Cancel",
                value: null,
                visible: true,
                className: "",
                closeModal: true,
            },
            confirm: {
                text: "Confirm",
                value: true,
                visible: true,
                className: "",
                closeModal: true
            }
        }
    }).then((confirmed) => {
        if (confirmed) {
            deleteRequest(request)
        }
    })
}

const buildEvent = (request, user, createEvent) => {
    let names = request.patientName.split(' ')
    const data = {
        title: request.title,
        description: request.description,
        date: moment(request.date).toISOString(),
        duration: request.duration,
        patient: {
            value: {
                email: request.patientEmail,
                firstname: names[0],
                lastname: names[1],
                uid: request.patientUID
            }
        }
    }
    createEvent(data, user)
}

const renderEditRequest = (props, request) => {

    let requestDate = undefined
    let onRequestChange = (val) => {
        requestDate = moment(val).toISOString();
    }

    const updateRequest = () => {
        let newTitle = document.querySelector(`#${request.id} .request-title`).value
        let newDescription = document.querySelector(`#${request.id} .request-description`).value
        let newDuration = parseInt(document.querySelector(`#${request.id} .request-duration`).value)
        let newDate = requestDate
        if (newTitle.length > 0) {
            request.title = newTitle
        }
        if (newDescription.length > 0) {
            request.description = newDescription
        }
        if (!isNaN(newDuration)) {
            request.duration = newDuration
        }
        if (requestDate !== undefined && requestDate !== null) {
            request.date = requestDate
            request.timezone = moment.tz.guess()
        }
        if (props.isProvider) {
            request.providerConfirmed = true
            request.patientConfirmed = false
        } else {
            request.providerConfirmed = false
            request.patientConfirmed = true
        }
        props.updateRequest(request, props.user, false)
    }

    let start = moment.tz(request.date, request.timezone).clone().tz(moment.tz.guess())
    let Cancelrequest = <button className="btn" onClick={() => { props.cancelEdit(request.id) }}>Cancel Editing</button>
    let Confirmrequest = <button className="btn" onClick={() => { updateRequest() }}>Update Request</button>
    let provider = <p>Provider: {request.providerName}</p>
    let patient = <p>Patient: {request.patientName}</p>
    return (
        <Card id={request.id} key={request.id}>
            <CardBody>
                <div>
                    <label htmlFor="request-title">Purpose: </label>
                    <input class="request-title" name="request-title" type="text" placeholder={request.title} />
                </div>
                <div>
                    <label htmlFor="request-description">Additional Information: </label>
                    <input class="request-description" name="request-description" type="text" placeholder={request.description} />
                </div>
                <div>
                    {provider}
                    {patient}
                </div>
                <div>
                    <div>
                        <label htmlFor="requestDate">Appointment Date and Time</label>
                        <DateTimePicker
                            class="requestDate"
                            name="requestDate"
                            onChange={onRequestChange}
                            format="DD MMM YYYY hh:mm a"
                            time={true}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="request-duration">Duration (mins): </label>
                    <input class="request-duration" name="request-duration" type="number" placeholder={request.duration} min="0" max="120" />
                </div>
                {Confirmrequest}
                {Cancelrequest}
            </CardBody>
        </Card>
    )
}

const renderViewRequest = (props, request) => {
    let start = moment.tz(request.date, request.timezone).clone().tz(moment.tz.guess())
    let Cancelrequest = <button className="cancel btn" onClick={() => { showCancellationPopUp(request, props.deleteRequest) }}>Cancel Request</button>
    let Confirmrequest = null
    if ((props.isProvider && !request.providerConfirmed) || (!props.isProvider && !request.patientConfirmed)) {
        Confirmrequest = <button className="confirm btn" onClick={() => { props.updateRequest(request, props.user, true) }}>Confirm Request</button>
    }
    let CreateAppointment = null
    if (props.isProvider && Confirmrequest === null && request.patientConfirmed === true && request.providerConfirmed === true) {
        CreateAppointment = <button className="btn" onClick={() => {
            request.scheduled = true
            props.updateRequest(request, props.user, false)
            buildEvent(request, props.user, props.createEvent)
        }}>Make Appointment</button>
    }
    let statusText = null
    if (!props.isProvider && Confirmrequest === null && request.patientConfirmed === true && request.providerConfirmed === true) {
        statusText = <CardText>Waiting for Provider to create appointment</CardText>
    }
    let Editrequest = <button className="btn" onClick={() => { props.editRequest(request.id) }}>Edit Request</button>
    return (
        <Card key={request.id}>
            <CardHeader className="heading"><h3>{request.patientName}</h3></CardHeader>
            <CardBody>
                <CardTitle className="sub-heading">{request.title}</CardTitle>

                <CardText>{request.description}</CardText>
                <CardText>Participants: {request.patientName} and {request.providerName}</CardText>
                <CardText>Start Time: {start.format('MMM Do YYYY h:mm a')}</CardText>
                <CardText>Duration: {request.duration}</CardText>
                <CardText>Patient Response: {request.patientConfirmed ? "Confirmed" : "Pending"}</CardText>
                <CardText>Provider Response: {request.providerConfirmed ? "Confirmed" : "Pending"}</CardText>
                {statusText}
                {CreateAppointment}
                {Confirmrequest}
                {Editrequest}
                {Cancelrequest}
            </CardBody>
        </Card>
    )
}

const RequestList = (props) => {
    if (props.requests.list !== null && props.requests.list !== undefined &&
        props.requests.list.length > 0) {
        return (
            <CardDeck className="request-container">
                {props.requests.list.sort((a, b) => {
                    let startA = moment.tz(a.date, a.timezone)
                    let startB = moment.tz(b.date, b.timezone)

                    if (startA.isBefore(startB)) {
                        return -1
                    } else if (startA.isAfter(startB)) {
                        return 1
                    } else {
                        return 0
                    }
                }).map(request => {
                    if (props.requests.isEditing.includes(request.id)) {
                        return renderEditRequest(props, request)
                    }
                    return renderViewRequest(props, request)
                })}
            </CardDeck>
        )
    } else {
        return (
            <div className="request-container"></div>
        )
    }
}

export default RequestList