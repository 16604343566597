import moment from 'moment-timezone';

const appointments = (state = {
    list: [],
    audits: []
}, action) => {
    switch (action.type) {
        case 'GET_APPOINTMENTS':
            if (!action.appointments) {
                return Object.assign({}, state, {
                    list: []
                })
            }

            let appointments = action.appointments
            let filtered = appointments.filter((doc) => {
                let end = moment.tz(doc.event.end.dateTime, doc.event.end.timezone).clone().tz(moment.tz.guess())
                return moment().isSameOrBefore(end)
            })
            filtered.sort((a, b) => {
                let aStart = moment.tz(a.event.start.dateTime, a.event.end.timezone).clone().tz(moment.tz.guess())
                let bStart = moment.tz(b.event.start.dateTime, b.event.end.timezone).clone().tz(moment.tz.guess())
                return bStart.isSameOrBefore(aStart)
            })
            let audits = appointments.filter((doc) => {
                let end = moment.tz(doc.event.end.dateTime, doc.event.end.timezone).clone().tz(moment.tz.guess())
                return moment().isAfter(end) && doc.audit === undefined
            })
            audits.sort((a, b) => {
                let aStart = moment.tz(a.event.start.dateTime, a.event.end.timezone).clone().tz(moment.tz.guess())
                let bStart = moment.tz(b.event.start.dateTime, b.event.end.timezone).clone().tz(moment.tz.guess())
                return bStart.isSameOrBefore(aStart)
            })
            return Object.assign({}, state, {
                list: filtered,
                audits
            })
        case 'ADD_APPOINTMENT':
            if (state.list) {
                let appointments = state.list.concat(action.appointment)
                let filtered = appointments.filter((doc) => {
                    let end = moment.tz(doc.event.end.dateTime, doc.event.end.timezone).clone().tz(moment.tz.guess())
                    return moment().isSameOrBefore(end)
                })
                filtered.sort((a, b) => {
                    let aStart = moment.tz(a.event.start.dateTime, a.event.end.timezone).clone().tz(moment.tz.guess())
                    let bStart = moment.tz(b.event.start.dateTime, b.event.end.timezone).clone().tz(moment.tz.guess())
                    return bStart.isSameOrBefore(aStart)
                })
                return Object.assign({}, state, {
                    list: filtered
                })
            } else {
                return Object.assign({}, state, {
                    list: [action.appointment]
                })
            }
        case 'DELETE_APPOINTMENT':
            if (state.list) {
                return Object.assign({}, state, {
                    list: state.list.filter(e => e.id !== action.appointment.id)
                })
            } else {
                return Object.assign({}, state, {
                    list: []
                })
            }
        case 'APPOINTMENT_SUBMITTING':
            return Object.assign({}, state, {
                isSubmitting: action.isSubmitting
            })
        default:
            return state
    }
}

export default appointments