import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import DropdownList from 'react-widgets/lib/DropdownList'
import './CustomForm.css'

const times = [{
  time: "Unavailable",
  value: -1
}, {
  time: "12:00am",
  value: 0
}, {
  time: "1:00am",
  value: 1
}, {
  time: "2:00am",
  value: 2
}, {
  time: "3:00am",
  value: 3
}, {
  time: "4:00am",
  value: 4
}, {
  time: "5:00am",
  value: 5
}, {
  time: "6:00am",
  value: 6
}, {
  time: "7:00am",
  value: 7
}, {
  time: "8:00am",
  value: 8
}, {
  time: "9:00am",
  value: 9
}, {
  time: "10:00am",
  value: 10
}, {
  time: "11:00am",
  value: 11
}, {
  time: "12:00pm",
  value: 12
}, {
  time: "1:00pm",
  value: 13
}, {
  time: "2:00pm",
  value: 14
}, {
  time: "3:00pm",
  value: 15
}, {
  time: "4:00pm",
  value: 16
}, {
  time: "5:00pm",
  value: 17
}, {
  time: "6:00pm",
  value: 18
}, {
  time: "7:00pm",
  value: 19
}, {
  time: "8:00pm",
  value: 20
}, {
  time: "9:00pm",
  value: 21
}, {
  time: "10:00pm",
  value: 22
},
{
  time: "11:00pm",
  value: 23
}]

const validate = values => {
  const errors = {}
  if (values.monday_start === undefined) {
    errors.monday_start = "Must choose a selection"
  } else if (values.monday_end === undefined) {
    errors.monday_end = "Must choose a selection"
  } else if (values.monday_start.value > values.monday_end.value) {
    errors.monday_end = "Must be greater than the start time"
  }

  if (values.tuesday_start === undefined) {
    errors.tuesday_start = "Must choose a selection"
  } else if (values.tuesday_end === undefined) {
    errors.tuesday_end = "Must choose a selection"
  } else if (values.tuesday_start.value > values.tuesday_end.value) {
    errors.tuesday_end = "Must be greater than the start time"
  }

  if (values.wednesday_start === undefined) {
    errors.wednesday_start = "Must choose a selection"
  } else if (values.wednesday_end === undefined) {
    errors.wednesday_end = "Must choose a selection"
  } else if (values.wednesday_start.value > values.wednesday_end.value) {
    errors.wednesday_end = "Must be greater than the start time"
  }

  if (values.thursday_start === undefined) {
    errors.thursday_start = "Must choose a selection"
  } else if (values.thursday_end === undefined) {
    errors.thursday_end = "Must choose a selection"
  } else if (values.thursday_start.value > values.thursday_end.value) {
    errors.thursday_end = "Must be greater than the start time"
  }

  if (values.friday_start === undefined) {
    errors.friday_start = "Must choose a selection"
  } else if (values.friday_end === undefined) {
    errors.friday_end = "Must choose a selection"
  } else if (values.friday_start.value > values.friday_end.value) {
    errors.friday_end = "Must be greater than the start time"
  }

  return errors
}

const renderDropdownList = ({ input, data, valueField, textField, value, meta: { touched, error, warning } }) => {
  return (<div>
    <DropdownList {...input}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={input.onChange} />
    {touched &&
      ((error && <span>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>)
}


const cancel = () => {
  window.location = "/"
}

let ScheduleSelectForm = props => {
  const { handleSubmit, submitting, initialValues } = props
  return (
    <form onSubmit={handleSubmit(props.onScheduleSubmit)}>
      <div className="day">
        <div>
          <label>Monday Start</label>
          <Field
            name="monday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Monday End</label>
          <Field
            name="monday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Tuesday Start</label>
          <Field
            name="tuesday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Tuesday End</label>
          <Field
            name="tuesday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Wednesday Start</label>
          <Field
            name="wednesday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Wednesday End</label>
          <Field
            name="wednesday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Thursday Start</label>
          <Field
            name="thursday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Thursday End</label>
          <Field
            name="thursday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Friday Start</label>
          <Field
            name="friday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Friday End</label>
          <Field
            name="friday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Saturday Start</label>
          <Field
            name="saturday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Saturday End</label>
          <Field
            name="saturday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>
      <div className="day">
        <div>
          <label>Sunday Start</label>
          <Field
            name="sunday_start"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
        <div>
          <label>Sunday End</label>
          <Field
            name="sunday_end"
            component={renderDropdownList}
            data={times}
            valueField="value"
            textField="time" />
        </div>
      </div>

      <div>
        <button type="submit" disabled={submitting}>
          Submit
        </button>
        <button type="button" disabled={submitting} onClick={cancel}>
          Cancel
        </button>
      </div>
    </form>
  )
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScheduleSelectForm = reduxForm({
  form: 'scheduleSelectForm', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  enableReinitialize: true
})(ScheduleSelectForm)

// You have to connect() to any reducers that you wish to connect to yourself
ScheduleSelectForm = connect(
  state => {
    if (state.user.profile === undefined || state.user.profile === null) {
      return {
        initialValues: {}
      }
    } else {
      if (state.user.profile.schedule !== undefined) {
        let schedule = state.user.profile.schedule
        // need to retroactively support saturday and sunday
        let saturdayValue
        if (schedule.saturday) {
          saturdayValue = schedule.saturday
        } else {
          saturdayValue = {
            start: -1,
            end: -1
          }
        }
        let sundayValue
        if (schedule.sunday) {
          sundayValue = schedule.sunday
        } else {
          sundayValue = {
            start: -1,
            end: -1
          }
        }
        const initial = {
          monday_start: { value: schedule.monday.start || -1 },
          monday_end: { value: schedule.monday.end || -1 },
          tuesday_start: { value: schedule.tuesday.start || -1 },
          tuesday_end: { value: schedule.tuesday.end || -1 },
          wednesday_start: { value: schedule.wednesday.start || -1 },
          wednesday_end: { value: schedule.wednesday.end || -1 },
          thursday_start: { value: schedule.thursday.start || -1 },
          thursday_end: { value: schedule.thursday.end || -1 },
          friday_start: { value: schedule.friday.start || -1 },
          friday_end: { value: schedule.friday.end || -1 },
          saturday_start: { value: saturdayValue.start },
          saturday_end: { value: saturdayValue.end },
          sunday_start: { value: sundayValue.start },
          sunday_end: { value: sundayValue.end },
        }
        return {
          initialValues: initial
        }
      } else {
        return {
          initialValues: {}
        }
      }

    }

  }
)(ScheduleSelectForm)

export default ScheduleSelectForm