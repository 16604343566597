import React from 'react'
import {
    Container,
} from 'reactstrap';
import Footer from '../Footer'
import Header from '../../containers/NavContainer'
import Spinner from '../LoadingSpinner'
import './PostDetail.css'

const createMarkup = content => {
    return { __html: content }
}

const PostDetail = (props) => {
    const post = props.post
    const user = props.user
    let isProvider = false
    if (user.current.email.includes('@anywhere.healthcare')) {
        isProvider = true
    }
    if (post !== undefined && post.content !== undefined) {
        return (<div>
            <Header logout={props.logout} isProvider={props.isProvider} />
            <Container fluid={true}>
                <h1>{post.title.rendered}</h1>
                <div dangerouslySetInnerHTML={createMarkup(post.content.rendered)}>
                </div>
            </Container>
            <Footer />
        </div>)
    } else {
        return (<div>
            <Header logout={props.logout} isProvider={props.isProvider} />
            <Container fluid={true}>
                <Spinner />
            </Container>
            <Footer />
        </div>)
    }

}

export default PostDetail