import React from 'react'
import {
  Container
} from 'reactstrap';
import Spinner from '../LoadingSpinner'
import Footer from '../Footer'
import Header from '../../containers/NavContainer'

const Home = (props) => (
  <div>
    <Header isHome={false} />
    <Container fluid={true} className="home">
      <Spinner />
    </Container>
    <Footer />
  </div>
)

export default Home
